<template>
  <v-dialog v-model="dialogPermissionUser" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ "Permiso de Acceso" }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="permissionForm">
            <v-row>
              <v-col cols="12" sm="8" md="8" class="py-0">
                <p class="mb-2">Módulos</p>
                <v-autocomplete
                  v-model="formPermission.idModule"
                  :items="modules"
                  :filter="customFilter"
                  outlined
                  dense
                  item-text="nombreModulo"
                  item-value="idModulo"
                  :rules="moduleRules"
                  no-data-text="No hay modulos disponibles"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0 d-flex align-center">
                <v-btn
                  color="blue darken-1"
                  dark
                  :loading="loading"
                  @click="saveAssignPermission"
                >
                  <span class="text-capitalize">Asignar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            :headers="headersPermissions"
            :items="permissionsUser"
            item-key="idPermiso"
            class="elevation-1 font-weight-light caption mt-2"
            fixed-header
            hide-default-footer
          >
            <template v-slot:[`item.asignado`]="{ item }">
              <div class="d-flex justify-center align-center">
                <v-checkbox
                  @change="changePermissionUser(item)"
                  v-model="item.asignado"
                  value
                  :disabled="loadingPermission"
                ></v-checkbox>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePermissionUser">
          <span class="text-capitalize">Cancelar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { usePermissions } from "@/composables";
export default {
  setup() {
    const {
      //props
      loading,
      dialogPermissionUser,
      modules,
      formPermission,
      moduleRules,
      permissionsUser,
      headersPermissions,
      permissionForm,
      loadingPermission,
      //methods
      saveAssignPermission,
      customFilter,
      closePermissionUser,
      changePermissionUser,
    } = usePermissions();
    return {
      //props
      loading,
      dialogPermissionUser,
      modules,
      formPermission,
      moduleRules,
      permissionsUser,
      headersPermissions,
      permissionForm,
      loadingPermission,
      //methods
      saveAssignPermission,
      customFilter,
      closePermissionUser,
      changePermissionUser,
    };
  },
};
</script>

<style>
</style>