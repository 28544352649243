<template>
  <v-layout align-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Nivel de Clase</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn color="#1486bd" dark @click="dialog = true">
          <span class="text-capitalize text-body">nuevo</span>
        </v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Nombre</p>
                      <v-text-field
                        v-model="formLessonLevel.nameLessonLevel"
                        class="pt-0"
                        placeholder="Nombre"
                        single-line
                        dense
                        outlined
                        :rules="nameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <p class="mb-2">Sigla</p>
                      <v-text-field
                        v-model="formLessonLevel.initials"
                        class="pt-0"
                        placeholder="Sigla"
                        single-line
                        dense
                        outlined
                        :rules="initialsRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <p class="mb-2">Descripcion</p>
                      <v-text-field
                        v-model="formLessonLevel.description"
                        class="pt-0"
                        placeholder="Descripcion"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingData"
                @click="close"
              >
                <span class="text-capitalize">Cancelar</span>
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                :loading="loadingData"
                @click="saveLessonLevel"
              >
                <span class="text-capitalize">Guardar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <p style="color: #1874bf" class="text-h6 text-center pt-4">
              Informe de Nivel de Clase
            </p>
            <v-card-text v-if="user.idRol == 1" class="text-center">
              {{
                `¿Esta seguro de ${
                  editedItem.estado ? "desactivar" : "activar"
                } el nivel de clase ${editedItem.nombreNivel}?`
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
              <v-btn dark color="blue darken-1" @click="deleteItemConfirm"
                >Si</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="lessonLevels.length > 0">
        <v-data-table
          :headers="headers"
          :items="lessonLevels"
          class="elevation-1 font-weight-light caption"
          @click:row="clickRow"
          fixed-header
        >
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="item.estado ? 'error' : 'success'"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{
                    item.estado ? "mdi-cancel" : "mdi-check"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.estado ? "Desactivar" : "Activar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-card elevation="0" outlined v-if="loading == false">
          <v-card-text class="text-center pb-0"
            >No existen Categorias de pago registrados.</v-card-text
          >
          <v-card-text class="text-center pt-0"
            ><v-btn
              color="blue darken-1"
              dark
              small
              class="my-2 px-2"
              tile
              elevation="0"
              @click="selectLessonLevels"
              >Volver a Intentar</v-btn
            ></v-card-text
          >
        </v-card>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Nombre",
          value: "nombreNivel",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Sigla",
          value: "sigla",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Descripcion",
          value: "descripcion",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      formLessonLevel: {
        idLessonLevel: "",
        nameLessonLevel: "",
        initials: "",
        description: "",
        idDepartament: "",
      },
      lessonLevels: [],
      editedIndex: -1,
      creationDate: new Date().toISOString().substring(0, 10),
      nameRules: [(value) => !!value || "Ingrese un nombre"],
      initialsRules: [(value) => !!value || "Ingrese una sigla"],
      loading: false,
      loadingData: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      editedItem: {},
      defaultItem: {},
      items: [
        {
          text: "Nivel de Clase",
          disabled: true,
          href: "lesson-level",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "idDepartament"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Nivel de Clase"
        : "Editar Nivel de Clase";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.selectLessonLevels();
  },
  methods: {
    clickRow() {
      //console.log(item);
      //this.$router.push('/group/'+item.idSemestre);
    },
    async deleteLessonLevel(data) {
      try {
        let response = await axios.put("lessonLevels/deleteLessonLevel", data);
        if (response.status == 200) {
          this.closeDelete();
          this.notification("warning", response.data["msg"]);
          this.selectLessonLevels();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertLessonLevel(data) {
      try {
        let response = await axios.post("lessonLevels/insertLessonLevel", data);
        if (response.status == 200) {
          this.close();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectLessonLevels();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async updateLessonLevel(data) {
      try {
        let response = await axios.put("lessonLevels/updateLessonLevel", data);
        if (response.status == 200) {
          this.close();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectLessonLevels();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveLessonLevel() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          //modificar
          let data = {
            idLevel: this.formLessonLevel.idLessonLevel,
            nameLevel: this.formLessonLevel.nameLessonLevel
              .trim()
              .toUpperCase(),
            initials: this.formLessonLevel.initials.trim().toUpperCase(),
            description: this.formLessonLevel.description,
          };
          this.loadingData = true;
          this.updateLessonLevel(data);
        } else {
          let data = {
            nameLevel: this.formLessonLevel.nameLessonLevel
              .trim()
              .toUpperCase(),
            initials: this.formLessonLevel.initials.trim().toUpperCase(),
            description: this.formLessonLevel.description,
            idDepartament: this.idDepartament,
          };
          this.loadingData = true;

          this.insertLessonLevel(data);
        }
      }
    },

    async selectLessonLevels() {
      try {
        this.loading = true;
        let response = await axios.get(
          `lessonLevels/selectLessonLevels/null/${this.idDepartament}`
        );
        if (response.status == 200) {
          console.log(response.data);
          this.lessonLevels = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },

    editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.formLessonLevel.idLessonLevel = this.editedItem.idNivel;
      this.formLessonLevel.nameLessonLevel = this.editedItem.nombreNivel;
      this.formLessonLevel.initials = this.editedItem.sigla;
      this.formLessonLevel.description = this.editedItem.descripcion;
      this.formLessonLevel.idDepartament = this.editedItem.idDepartamento;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let data = {
        idLevel: this.editedItem.idNivel,
        state: this.editedItem.estado ? 0 : 1,
      };
      this.deleteLessonLevel(data);
    },

    close() {
      this.dialog = false;
      this.editedIndex = -1;
      this.formLessonLevel.idLessonLevel = "";
      this.formLessonLevel.nameLessonLevel = "";
      this.formLessonLevel.initials = "";
      this.formLessonLevel.description = "";
      this.formLessonLevel.idDepartament = "";
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style>
.td-click td {
  cursor: pointer;
}
</style>