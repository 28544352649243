<template>
  <v-layout>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Reporte Pago de Mensualidades</v-toolbar-title>
      </v-toolbar>
      <v-card outlined tile>
        <v-card-text class="mt-1">
          <p class="font-weight-bold text-body-1" style="color: #1486bd">
            Buscar Reporte
          </p>
          <v-form ref="formSearchReport">
            <v-row>
              <v-col cols="12" sm="4" md="4" class="py-0">
                <p class="mb-2">Nombre Grupo</p>
                <v-text-field
                  v-model="formSearchReport.groupName"
                  class="pt-0"
                  placeholder="Nombre Grupo"
                  single-line
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <p class="mb-2">Turno</p>
                <v-select
                  v-model="formSearchReport.idTurn"
                  :items="turns"
                  item-text="nombreTurno"
                  item-value="idTurno"
                  label="Seleccione una opcion"
                  persistent-hint
                  outlined
                  single-line
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <p class="mb-2">Semestre</p>
                <v-select
                  v-model="formSearchReport.idSemester"
                  :items="semesters"
                  item-text="nombreSemestre"
                  item-value="idSemestre"
                  label="Seleccione una opcion"
                  persistent-hint
                  outlined
                  single-line
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <p class="mb-2">Fecha de Inicio</p>
                <v-menu
                  ref="menuDay"
                  v-model="menuDay"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formSearchReport.date"
                      placeholder="Fecha"
                      readonly
                      single-line
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formSearchReport.date"
                    no-title
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="2010-01-01"
                    @change="saveDay"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!--v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Fecha Inicial</p>
                                <v-menu
                                    ref="menuStart"
                                    v-model="menuStart"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="formSearchReport.startDate"
                                        placeholder="Fecha Inicial"
                                        readonly
                                        single-line
                                        dense
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="formSearchReport.startDate"
                                        no-title
                                        :active-picker.sync="activePicker"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                        min="2010-01-01"
                                        @change="saveStart"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Fecha Final</p>
                                <v-menu
                                    ref="menuEnd"
                                    v-model="menuEnd"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="formSearchReport.endDate"
                                        placeholder="Fecha Final"
                                        readonly
                                        single-line
                                        dense
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="formSearchReport.endDate"
                                        no-title
                                        :active-picker.sync="activePicker"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                        min="2010-01-01"
                                        @change="saveEnd"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col-->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 mb-2">
          <v-spacer></v-spacer>
          <v-btn color="warning" elevation="0" @click="cleanFormSearchReport">
            <span class="text-capitalize text-body">Limpiar</span>
          </v-btn>
          <v-btn
            class="ml-2"
            dark
            color="#1486bd"
            elevation="0"
            @click="searchReportByParams"
          >
            <span class="text-capitalize text-body">Buscar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <template v-if="loadingSearchIndividualReport">
        <v-container class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-container>
      </template>
      <div v-show="searchMonthlyPaymentReport.length > 0">
        <v-data-table
          :headers="headersMonthlyPayment"
          :items="searchMonthlyPaymentReport"
          :items-per-page="-1"
          class="elevation-1 font-weight-light caption"
          hide-default-footer
          fixed-header
        >
          <template v-slot:[`item.fechaInicio`]="{ item }">
            {{ formatDate(item.fechaInicio.substring(0, 10)) }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3 mr-2"
                  color="primary"
                  fab
                  depressed
                  icon
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="printItem(item)"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Reporte</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3 mr-2"
                  color="#1D6F42"
                  fab
                  depressed
                  icon
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadXlsx(item)"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </template>
              <span>Descargar Excel</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogDownload" hide-overlay persistent width="300">
        <v-card color="#1486bd" dark>
          <v-card-text>
            <p class="mb-1 pt-2 text-center">Generando Reporte</p>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <ReportMonthlyPayment
        ref="reportMonthlyPayment"
        :monthlyPaymentReport="monthlyPaymentReport"
        :group="group"
        :user="user"
        :departament="nameDepartament"
      />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ReportMonthlyPayment from "@/components/report/report-monthly-payment-component.vue";
import * as XLSX from "xlsx";
export default {
  components: {
    ReportMonthlyPayment,
  },
  data() {
    return {
      //Individual Report
      headersMonthlyPayment: [
        {
          text: "Grupo",
          value: "nombreGrupo",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Turno",
          value: "nombreTurno",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Cuotas",
          value: "cuotas",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Fecha Inicio",
          value: "fechaInicio",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      formSearchReport: {
        groupName: "",
        idTurn: "",
        idSemester: "",
        date: "",
        startDate: "",
        endDate: "",
      },
      searchMonthlyPaymentReport: [],
      monthlyPaymentReport: [],
      turns: [],
      semesters: [],
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      items: [
        {
          text: "Reporte Pago Mensualidades",
          disabled: false,
          href: "/report-monthly-payment",
        },
      ],

      dialogDownload: false,
      activePicker: null,
      menuDay: null,
      loadingSearchIndividualReport: false,
      group: {},
      nameDepartament: "",
    };
  },
  computed: {
    ...mapState(["idDepartament", "user"]),
  },
  watch: {},
  created() {
    this.user.idRol == 1
      ? this.selectDepartamentById()
      : (this.nameDepartament = this.user.departamento);
    //this.selectReportGroups();
    this.selectTurns();
    this.selectSemesters();
  },
  methods: {
    //export excel
    async download(idGroup) {
      try {
        this.monthlyPaymentReport = [];
        let response = await axios.get(
          `reports/selectMonthlyPaymentReport/${idGroup}`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
            this.dialogDownload = false;
            return;
          }
          await response.data.map((value, index) => {
            this.monthlyPaymentReport.push({
              nro: index + 1,
              nombres: value.apellidos + " " + value.nombres,
              ci: value.ci,
              telefono: value.telefono,
              fecha1:
                value.fecha1 == null
                  ? 0
                  : this.formatDate(value.fecha1.substring(0, 10)),
              pago1: value.pago1,
              saldo1: value.saldo1,
              fecha2:
                value.fecha2 == null
                  ? 0
                  : this.formatDate(value.fecha2.substring(0, 10)),
              pago2: value.pago2,
              saldo2: value.saldo2,
              fecha3:
                value.fecha3 == null
                  ? 0
                  : this.formatDate(value.fecha3.substring(0, 10)),
              pago3: value.pago3,
              saldo3: value.saldo3,
              fecha4:
                value.fecha4 == null
                  ? 0
                  : this.formatDate(value.fecha4.substring(0, 10)),
              pago4: value.pago4,
              saldo4: value.saldo4,
            });
          });

          let wb = XLSX.utils.book_new();

          const data = XLSX.utils.json_to_sheet(this.monthlyPaymentReport, {
            header: [
              "nro",
              "nombres",
              "ci",
              "telefono",
              "fecha1",
              "pago1",
              "saldo1",
              "fecha2",
              "pago2",
              "saldo2",
              "fecha3",
              "pago3",
              "saldo3",
              "fecha4",
              "pago4",
              "saldo4",
            ],
          });
          data["A1"].v = "Nro";
          data["B1"].v = "Fecha";
          data["C1"].v = "CI";
          data["D1"].v = "Telefono";
          data["E1"].v = "Fecha 1";
          data["F1"].v = "Pago 1";
          data["G1"].v = "Saldo 1";
          data["H1"].v = "Fecha 2";
          data["I1"].v = "Pago 2";
          data["J1"].v = "Saldo 2";
          data["K1"].v = "Fecha 3";
          data["L1"].v = "Pago 3";
          data["M1"].v = "Saldo 3";
          data["N1"].v = "Fecha 4";
          data["O1"].v = "Pago 4";
          data["P1"].v = "Saldo 4";
          XLSX.utils.book_append_sheet(wb, data, "Pago de Mensualidades");

          XLSX.writeFile(
            wb,
            `ReportePagoMensualidades${this.group.nombreGrupo}.xlsx`
          );
          this.dialogDownload = false;
        }
      } catch (error) {
        console.log(error);
        this.dialogDownload = false;
      }
    },
    downloadXlsx(item) {
      this.dialogDownload = true;
      this.group = item;
      this.download(item.idGrupo);
    },
    //other Module
    async selectDepartamentById() {
      try {
        let response = await axios.get(
          `departaments/selectDepartamentById/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.nameDepartament = response.data.nombreDepartamento;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectMonthlyPaymentReport(idGroup) {
      try {
        this.monthlyPaymentReport = [];
        let response = await axios.get(
          `reports/selectMonthlyPaymentReport/${idGroup}`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
            this.dialogDownload = false;
            return;
          }

          await response.data.map((value, index) => {
            this.monthlyPaymentReport.push({
              nro: index + 1,
              idGrupoUsuario: value.idGrupoUsuario,
              nombres: value.nombres,
              apellidos: value.apellidos,
              ci: value.ci,
              telefono: value.telefono,
              fecha1: value.fecha1,
              pago1: value.pago1,
              saldo1: value.saldo1,
              fecha2: value.fecha2,
              pago2: value.pago2,
              saldo2: value.saldo2,
              fecha3: value.fecha3,
              pago3: value.pago3,
              saldo3: value.saldo3,
              fecha4: value.fecha4,
              pago4: value.pago4,
              saldo4: value.saldo4,
              fecha5: value.fecha5,
              pago5: value.pago5,
              saldo5: value.saldo5,
              fecha6: value.fecha6,
              pago6: value.pago6,
              saldo6: value.saldo6,
            });
          });

          this.$refs.reportMonthlyPayment.generatePdf();
          this.dialogDownload = false;
        }
      } catch (error) {
        console.log(error);
        this.dialogDownload = false;
      }
    },
    printItem(item) {
      this.dialogDownload = true;
      this.group = item;
      this.selectMonthlyPaymentReport(item.idGrupo);
    },
    async selectSearchReportGroups(url) {
      try {
        this.searchMonthlyPaymentReport = [];
        let response = await axios.get(`reports/selectReportGroups/${url}`);
        if (response.status == 200) {
          this.loadingSearchIndividualReport = false;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro grupos");
            return;
          }
          this.searchMonthlyPaymentReport = response.data;
        }
      } catch (error) {
        console.log(error);
        this.loadingSearchIndividualReport = false;
      }
    },
    searchReportByParams() {
      let groupName =
        this.formSearchReport.groupName == ""
          ? null
          : this.formSearchReport.groupName;
      let idTurn =
        this.formSearchReport.idTurn == ""
          ? null
          : this.formSearchReport.idTurn;
      let idSemester =
        this.formSearchReport.idSemester == ""
          ? null
          : this.formSearchReport.idSemester;
      let date =
        this.formSearchReport.date == "" ? null : this.formSearchReport.date;

      let url = `${this.idDepartament}/${groupName}/${idTurn}/${idSemester}/${date}`;

      this.loadingSearchIndividualReport = true;
      this.selectSearchReportGroups(url);
    },
    cleanFormSearchReport() {
      this.formSearchReport.groupName = "";
      this.formSearchReport.idTurn = "";
      this.formSearchReport.idSemester = "";
      this.formSearchReport.date = "";
      this.selectReportGroups();
    },
    async selectReportGroups() {
      this.loadingSearchIndividualReport = true;
      try {
        this.searchMonthlyPaymentReport = [];
        let response = await axios.get(
          `reports/selectReportGroups/${this.idDepartament}/null/null/null/null`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro grupos");
            return;
          }
          this.searchMonthlyPaymentReport = response.data;
          this.loadingSearchIndividualReport = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingSearchIndividualReport = false;
      }
    },
    async selectTurns() {
      try {
        let response = await axios.get(
          `turns/selectTurns/1/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.turns = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectSemesters() {
      try {
        let response = await axios.get(
          `semesters/selectSemesters/${this.idDepartament}/null/1`
        );
        if (response.status == 200) {
          this.semesters = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    print() {
      this.totalResume = {
        totalIndividualIngress: this.totalIndividualIngress,
        totalGroupIngress: this.totalGroupIngress,
        totalIngress: this.totalIngress,
        totalEgress: this.totalEgress,
        total:
          this.totalIndividualIngress +
          this.totalGroupIngress +
          this.totalIngress,
      };
      this.$refs.reportIngress.generatePdf();
    },
    saveDay(date) {
      this.$refs.menuDay.save(date);
      this.formSearchReport.startDate = "";
      this.formSearchReport.endDate = "";
    },
    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
.td-click td {
  cursor: pointer;
}
</style>