<template>
  <v-layout align-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Usuarios</v-toolbar-title>
        <v-switch
          v-model="active"
          class="ml-4"
          :label="messageSwitch"
          @change="changeState"
          hide-details
        ></v-switch>

        <v-spacer></v-spacer>
        <v-btn color="#1486bd" dark @click="dialog = true">
          <span class="text-capitalize text-body">nuevo</span>
        </v-btn>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Nombres</p>
                      <v-text-field
                        v-model="formUser.names"
                        class="pt-0"
                        label="Nombres"
                        placeholder="Nombres"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="nameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Apellidos</p>
                      <v-text-field
                        v-model="formUser.surnames"
                        class="pt-0"
                        label="Apellidos"
                        placeholder="Apellidos"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="surnameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Ci</p>
                      <v-text-field
                        v-model="formUser.ci"
                        class="pt-0"
                        label="Ci"
                        placeholder="Ci"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="ciRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Telefono</p>
                      <v-text-field
                        v-model="formUser.phone"
                        class="pt-0"
                        label="Telefono"
                        placeholder="Telefono"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="phoneRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Usuario</p>
                      <v-text-field
                        v-model="formUser.user"
                        class="pt-0"
                        label="Usuario"
                        placeholder="Usuario"
                        single-line
                        dense
                        autocomplete="null"
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Contraseña</p>
                      <v-text-field
                        v-model="formUser.password"
                        class="pt-0"
                        :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="passwordShow ? 'text' : 'password'"
                        label="Password"
                        placeholder="Password"
                        @click:append="passwordShow = !passwordShow"
                        autocomplete="null"
                        single-line
                        dense
                        clearable
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <p class="mb-2">Email</p>
                      <v-text-field
                        v-model="formUser.email"
                        class="pt-0"
                        label="Email"
                        placeholder="Email"
                        single-line
                        dense
                        outlined
                        autocomplete="null"
                        :rules="emailRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Sucursal</p>
                      <v-select
                        v-model="formUser.idBranchoffice"
                        :items="branchoffices"
                        item-text="nombreSucursal"
                        item-value="idSucursal"
                        label="Sucursal"
                        persistent-hint
                        dense
                        outlined
                        single-line
                        :rules="branchofficeRules"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Rol</p>
                      <v-select
                        v-model="formUser.idRole"
                        :items="roles"
                        item-text="nombreRol"
                        item-value="idRol"
                        label="Rol"
                        persistent-hint
                        dense
                        outlined
                        single-line
                        :rules="roleRules"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingData"
                @click="close"
              >
                <span class="text-capitalize">Cancelar</span>
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                :loading="loadingData"
                @click="saveUser"
              >
                <span class="text-capitalize">Guardar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <p style="color: #1874bf" class="text-h6 text-center pt-4">
              Informe de Usuario
            </p>
            <v-card-text v-if="store.state.user.idRol == 1" class="text-center">
              {{
                `¿Esta seguro de ${
                  editedItem.estado ? "desactivar" : "activar"
                } el usuario ${editedItem.nombres}?`
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
              <v-btn dark color="blue darken-1" @click="deleteItemConfirm"
                >Si</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="users.length > 0">
        <v-data-table
          :headers="headers"
          :items="users"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption"
          fixed-header
        >
          <template v-slot:[`item.fechaInicio`]="{ item }">
            {{ formatDate(item.fechaInicio.substring(0, 10)) }}
          </template>
          <template v-slot:[`item.password`]="{ item }">
            {{ show ? item.password : "......." }}
            <v-btn small icon @click="showPassword(item)">
              <v-icon small>{{ show ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <template v-if="item.idRol === 2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="green"
                    class="mr-2"
                    @click.stop="editPermissions(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Cambiar Permisos</span>
              </v-tooltip>
            </template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="warning"
                  class="mr-2"
                  @click.stop="changeCredentials(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account-key</v-icon>
                </v-btn>
              </template>
              <span>Cambiar Credenciales</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="item.estado ? 'error' : 'success'"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{
                    item.estado ? "mdi-cancel" : "mdi-check"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.estado ? "Desactivar" : "Activar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-card elevation="0" outlined v-if="loading == false">
          <v-card-text class="text-center pb-0"
            >No existen Usuarios Registrados.</v-card-text
          >
          <v-card-text class="text-center pt-0"
            ><v-btn
              color="blue darken-1"
              dark
              small
              class="my-2 px-2"
              tile
              elevation="0"
              @click="selectUsers"
              >Volver a Intentar</v-btn
            ></v-card-text
          >
        </v-card>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <CredentialChangeUser
        ref="credentialChange"
        @notification="notificationChange"
        @deleteUserSelected="deleteUserSelected"
        :user="userSelected"
      />
      <DialogPermissionsUser />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import crypto from "crypto-js";
import CredentialChangeUser from "@/components/credential/credential-change-user-component.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "@/store";
import { usePermissions } from "@/composables";
import DialogPermissionsUser from "@/components/administrator/dialog-permissions-user.vue";
export default {
  components: {
    CredentialChangeUser,
    DialogPermissionsUser,
  },
  setup() {
    //props
    const dialog = ref(false);
    const dialogDelete = ref(false);
    const headers = ref([
      {
        text: "Ci",
        value: "ci",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Apellidos",
        value: "apellidos",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Nombres",
        value: "nombres",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Email",
        value: "email",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      //{text: "Usuario", value: "usuario", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
      //{text: "Password", value: "password", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
      {
        text: "Rol",
        value: "nombreRol",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Acciones",
        value: "accion",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
    ]);
    const users = ref([]);
    const editedIndex = ref(-1);
    const formUser = ref({
      idUser: "",
      names: "",
      surnames: "",
      ci: "",
      email: "",
      phone: "",
      idBranchoffice: "",
      idRole: "",
      creationDate: new Date().toISOString().substring(0, 10),
      user: "",
      password: "",
      passwordBefore: "",
    });
    const nameRules = ref([(value) => !!value || "Ingrese nombres"]);
    const surnameRules = ref([(value) => !!value || "Ingrese apellidos"]);
    const ciRules = ref([(value) => !!value || "Ingrese un ci"]);
    const emailRules = ref([
      (value) => !!value || "Ingrese un email",
      (value) => /.+@.+\..+/.test(value) || "Debe ingresar un email correcto",
    ]);
    const phoneRules = ref([
      (value) => !!value || "Ingrese un numero de telefono",
      (value) => /^[0-9]+$/.test(value) || "Debe ingresar un telefono valido",
    ]);
    const branchofficeRules = ref([
      (value) => !!value || "Seleccione una sucursal",
    ]);
    const roleRules = ref([(value) => !!value || "Seleccione un rol"]);
    const loading = ref(false);
    const loadingData = ref(false);
    const snackbar = ref(false);
    const colorSnackbar = ref("");
    const textSnackbar = ref("");
    const editedItem = ref({});
    const defaultItem = ref({
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    });
    const items = ref([
      {
        text: "Usuarios",
        disabled: false,
        href: "/user",
      },
    ]);
    const menu2 = ref(false);
    const branchoffices = ref([]);
    const roles = ref([]);
    const show = ref(false);
    const passwordShow = ref(false);
    const userSelected = ref({});
    const active = ref(true);
    const store = useStore();
    const form = ref(null);
    const credentialChange = ref(null);
    const { editPermissions } = usePermissions();

    onMounted(() => {
      selectUsers();
      selectBrancoffices();
      selectRoles();
    });

    //computed
    const formTitle = computed(() =>
      editedIndex.value === -1 ? "Nuevo Usuario" : "Editar Usuario"
    );
    const computedDateFormatted = computed(() =>
      formatDate(formUser.value.startDate)
    );
    const messageSwitch = computed(() =>
      active.value ? "Activos" : "Inactivos"
    );

    //watch
    watch(dialog, (val) => val || close());
    watch(dialogDelete, (val) => val || closeDelete());

    //methods
    const changeState = (value) => {
      selectUsers();
      console.log(value);
    };

    const notificationChange = (response) => {
      notification(response.type, response.text);
      selectUsers();
    };

    const deleteUserSelected = () => {
      userSelected.value = {};
    };

    const changeCredentials = (item) => {
      userSelected.value = item;
      credentialChange.value.openDialog();
    };

    const showPassword = (item) => {
      console.log(item);
      show.value = !show.value;
    };

    const clickRow = () => {};

    const deleteUser = async (data) => {
      try {
        let response = await axios.put("users/deleteUser", data);
        if (response.status == 200) {
          closeDelete();
          notification("warning", response.data["msg"]);
          selectUsers();
        }
      } catch (error) {
        console.log(error);
        notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    };

    const insertUser = async (data) => {
      try {
        let response = await axios.post("users/insertUser", data);
        if (response.status == 200) {
          close();
          loadingData.value = false;
          notification("success", response.data["msg"]);
          selectUsers();
        }
      } catch (error) {
        console.log(error);
        loadingData.value = false;
        notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    };

    const updateUser = async (data) => {
      try {
        let response = await axios.put("users/updateUser", data);
        if (response.status == 200) {
          close();
          loadingData.value = false;
          notification("success", response.data["msg"]);
          selectUsers();
        }
      } catch (error) {
        console.log(error);
        loadingData.value = false;
        notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    };

    const saveUser = async () => {
      const valid = await form.value.validate();
      if (valid) {
        if (editedIndex.value > -1) {
          //modificar
          let data = {
            names: formUser.value.names.toUpperCase().trim(),
            surnames: formUser.value.surnames.toUpperCase().trim(),
            ci: formUser.value.ci.trim(),
            email: formUser.value.email.trim(),
            phone: formUser.value.phone.trim(),
            idBranchoffice: formUser.value.idBranchoffice,
            idRole: formUser.value.idRole,
            idUser: formUser.value.idUser,
            user: formUser.value.user.trim(),
            password: formUser.value.password.trim(),
            passwordBefore: formUser.value.passwordBefore.trim(),
          };
          loadingData.value = true;
          updateUser(data);
        } else {
          let data = {
            names: formUser.value.names.toUpperCase().trim(),
            surnames: formUser.value.surnames.toUpperCase().trim(),
            ci: formUser.value.ci.trim(),
            email: formUser.value.email.trim(),
            phone: formUser.value.phone.trim(),
            idBranchoffice: formUser.value.idBranchoffice,
            idRole: formUser.value.idRole,
            user: formUser.value.user,
            pass: formUser.value.password,
          };
          loadingData.value = true;

          insertUser(data);
        }
      }
    };

    const selectUsers = async () => {
      try {
        loading.value = true;
        let response = await axios.get(
          `users/selectUsers/${store.state.idDepartament}/${active.value}`
        );
        if (response.status == 200) {
          users.value = response.data;
          loading.value = false;
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    const selectBrancoffices = async () => {
      try {
        let response = await axios.get(
          `branchoffices/selectBranchoffices/${store.state.idDepartament}/1`
        );
        if (response.status == 200) {
          branchoffices.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const selectRoles = async () => {
      try {
        let response = await axios.get("roles/selectRoles/1");
        if (response.status == 200) {
          roles.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const notification = (type, text) => {
      snackbar.value = true;
      colorSnackbar.value = type;
      textSnackbar.value = text;
    };

    const editItem = (item) => {
      editedIndex.value = 1;
      editedItem.value = Object.assign({}, item);
      formUser.value.idUser = editedItem.value.idUsuario;
      formUser.value.names = editedItem.value.nombres;
      formUser.value.surnames = editedItem.value.apellidos;
      formUser.value.ci = editedItem.value.ci;
      formUser.value.email = editedItem.value.email;
      formUser.value.phone = editedItem.value.telefono;
      formUser.value.idBranchoffice = editedItem.value.idSucursal;
      formUser.value.idRole = editedItem.value.idRol;
      formUser.value.user = editedItem.value.usuario;
      formUser.value.password = editedItem.value.password;
      formUser.value.passwordBefore = editedItem.value.password;
      dialog.value = true;
    };

    const decryptPassword = (encrypt) => {
      const passDecrypt = crypto.AES.encrypt(
        "hellomoto25784ytT",
        "w9z$B&E)H@McQfTjWnZr4u7x!A%D*F-JaNdRgUkXp2s5v8y/B?E(H+KbPeSh"
      );
      console.log(passDecrypt);
      console.log(crypto.enc.Base64.parse(encrypt));
    };

    const deleteItem = (item) => {
      editedItem.value = Object.assign({}, item);
      dialogDelete.value = true;
    };

    const deleteItemConfirm = () => {
      let data = {
        idUser: editedItem.value.idUsuario,
        state: editedItem.value.estado ? 0 : 1,
      };
      deleteUser(data);
    };

    const close = () => {
      editedIndex.value = -1;
      form.value.resetValidation();

      formUser.value.idUser = "";
      formUser.value.names = "";
      formUser.value.surnames = "";
      formUser.value.ci = "";
      formUser.value.email = "";
      formUser.value.phone = "";
      formUser.value.idBranchoffice = "";
      formUser.value.idRole = "";
      formUser.value.user = "";
      formUser.value.password = "";
      dialog.value = false;
    };

    const closeDelete = () => {
      dialogDelete.value = false;
      editedItem.value = "";
    };

    const formatDate = (date) => {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };

    return {
      //props
      dialog,
      dialogDelete,
      headers,
      users,
      editedIndex,
      formUser,
      nameRules,
      surnameRules,
      ciRules,
      emailRules,
      phoneRules,
      branchofficeRules,
      roleRules,
      loading,
      loadingData,
      snackbar,
      colorSnackbar,
      textSnackbar,
      editedItem,
      defaultItem,
      items,
      menu2,
      branchoffices,
      roles,
      show,
      passwordShow,
      userSelected,
      active,
      form,
      credentialChange,
      store,
      //methods
      formTitle,
      computedDateFormatted,
      messageSwitch,
      changeState,
      notificationChange,
      deleteUserSelected,
      changeCredentials,
      showPassword,
      clickRow,
      saveUser,
      editItem,
      decryptPassword,
      deleteItem,
      deleteItemConfirm,
      editPermissions,
      close,
      closeDelete,
      selectUsers,
    };
  },
};
</script>

<style>
/*.td-click td{
		/*cursor: pointer;
	}*/
</style>