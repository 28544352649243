import apiNewton from "./apiNewton";

export const getModules = (params) =>
	apiNewton.get("/users/selectModules", { params: params });

export const getPermissionUser = (params) =>
	apiNewton.get("/users/selectPermissionsUser", { params: params });

export const setPermissionUser = (data) =>
	apiNewton.post("/users/insertPermissionUser", data);

export const updatePermission = (data) =>
	apiNewton.put("/users/updatePermissionUser", data);
