<template>
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="800"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="letter"
    pdf-orientation="landscape"
    pdf-content-width="1340px"
    :html-to-pdf-options="htmlToPdfOptions"
    ref="html2Pdf"
  >
    <section id="pdf" ref="pdf" class="mx-5 section" slot="pdf-content">
      <div ref="header" class="row mt-0 pl-0">
        <div class="col-6 pb-0 pl-0 pt-0">
          <p class="mt-0 mb-0 text-small section">
            {{ user.departamento }} - BOLIVIA
          </p>
          <p
            v-if="user.idDepartamento == idDepartament"
            class="mt-0 mb-0 text-medium section"
          >
            {{ user.sucursal }}
          </p>
          <p
            v-if="user.idDepartamento == idDepartament"
            class="mt-0 mb-0 text-medium section"
          >
            {{ user.telefonoSucursal }}
          </p>
        </div>
        <div class="col-6 pb-0">
          <div class="row justify-content-end">
            <img
              :src="require('../../assets/blueLogo2.png')"
              height="80"
              alt="..."
            />
          </div>
        </div>
      </div>
      <div id="title" class="container mt-n2 pt-0 pb-2 mb-2">
        <div class="row mt-0 justify-content-center">
          <div class="col-12 align-self-center py-0">
            <p class="font-weight-bold h6 mb-0 text-center section">
              REPORTE DE ASISTENCIA {{ group.dateString }}
            </p>
          </div>
        </div>
      </div>
      <div id="detail" class="row mt-0 mb-1">
        <div class="col-8">
          <div class="row">
            <p class="mt-1 font-weight-bold text-small section">
              {{ "USUARIO: " }}
            </p>
            <p class="mt-1 pl-1 text-small section">
              {{ user.nombres + " " + user.apellidos }}
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="row justify-content-between">
            <p class="mt-0 text-small section">Fecha:</p>
            <p class="mt-0 text-small section">{{ formatDateTimeReceipt() }}</p>
          </div>
        </div>
      </div>

      <div class="col-12 py-0 pb-1">
        <p class="text-center mb-0 font-weight-bold small section">
          Lista de estudiantes
        </p>
      </div>
      <table
        id="table-ingress"
        cellspacing="0"
        class="table table-sm table-bordered mb-0"
      >
        <thead class="thead-dark">
          <tr>
            <th v-for="i in headers" :key="i" class="section" scope="col">
              {{ i.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in studentsAssistance" :key="item.idUsuario">
            <td
              v-for="(col, index) in headers"
              :key="index"
              :style="`${index === 0 ? 'border-left: 1px solid #1486bd' : ''}`"
              :class="`py-0 px-1 pt-1 pb-1 section`"
            >
              <template v-if="index < 3">
                <p class="section text-small">
                  {{ item[col.value] }}
                </p>
              </template>
              <template v-else>
                <p class="section text-small text-center">
                  {{ item[col.value] }}
                </p>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
//import jsPDF from "jspdf";
import { mapState } from "vuex";
export default {
  props: {
    studentsAssistance: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: () => {},
    },
    group: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      htmlToPdfOptions: {
        margin: [0.4, 0, 0.4, 0],

        filename: `Reporte de Asistencia.pdf`,

        image: {
          type: "jpeg",
          quality: 1,
        },

        enableLinks: false,

        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        pagebreak: {
          mode: ["avoid-all"],
        },
        jsPDF: {
          unit: "in",
          format: "legal",
          orientation: "landscape",
        },
      },
    };
  },
  computed: {
    ...mapState(["idDepartament"]),
  },
  created() {},

  methods: {
    progress(status) {
      if (status == 100) {
        this.$emit("downloadStop");
      }
    },
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
      /*const pdf = new jsPDF("l", "px", "a4");
      const header = this.$refs.pdf.innerHTML;

      pdf.html(header, {
        callback: (pdf) => {
          pdf.save(`Reporte de asistencia.pdf`);
          this.$emit("downloadStop");
          //pdf.output("pdfjsnewwindow");
        },
        x: 10,
        y: 10,
        width: 400,
        height: 1040,
        windowWidth: 792,
        margin: 20,
      });*/
    },

    formatDateTimeReceipt() {
      let date = new Date();
      date.setHours(date.getHours() - 4);
      let dateSub = date.toISOString().substring(0, 10);
      let timeSub = date.toISOString().substring(11, 19);
      const [year, month, day] = dateSub.split("-");
      return `${day}-${month}-${year} ${timeSub}`;
    },
  },
};
</script>
<style scoped>
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.section {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.text-small {
  font-size: 13px;
}
.text-medium {
  font-size: 11px;
}
.text-tb-small {
  font-size: 10px;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h6,
.h6 {
  font-size: 1rem;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
table {
  border-collapse: collapse;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 0px solid #1486bd;
}

.table-bordered th {
  border: 0;
}
.table-bordered td {
  border: 1px solid #1486bd;
  border-left: 0;
}
.table .thead-dark th {
  color: #fff;
  background-color: #1486bd;
  border-color: #1486bd;
  font-size: 12px;
}
table,
tr,
td {
  page-break-inside: avoid;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.text-right {
  text-align: right !important;
}
small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}
.p-0 {
  padding: 0 !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
</style>