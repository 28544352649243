<template>
  <v-layout align-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Ingreso</v-toolbar-title>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :color="search ? 'red' : 'blue'"
              class="mx-1"
              @click.stop="openAdvancedSearch"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ search ? "mdi-magnify-close" : "mdi-magnify" }}</v-icon
              >
            </v-btn>
          </template>
          <span>{{ search ? "Cerrar Busqueda" : "Busqueda Avanzada" }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-btn color="red darken-2" dark @click="openDialog(1)">
          <span class="text-capitalize text-body-2">Agregar ingreso</span>
        </v-btn>
        <v-btn class="mx-2" color="primary" dark @click="openDialog(2)">
          <span class="text-capitalize text-body-2">Ingreso por Paquetes</span>
        </v-btn>
        <v-btn color="red darken-2" dark @click="openDialog(3)">
          <span class="text-capitalize text-body-2">Ingreso por Practica</span>
        </v-btn>
        <v-dialog v-model="dialog" max-width="550px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Pago</p>
                      <v-text-field
                        v-model="formIngress.total"
                        class="pt-0"
                        placeholder="Pago"
                        single-line
                        dense
                        outlined
                        :disabled="disabled"
                        :rules="paymentRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="mb-2">Tipo</p>
                      <v-select
                        v-model="formIngress.idIngressType"
                        class="pt-0"
                        :items="ingressTypes"
                        item-text="nombreTipoIngreso"
                        item-value="idTipoIngreso"
                        label="Seleccione una opcion"
                        persistent-hint
                        single-line
                        dense
                        outlined
                        :rules="typeRules"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <p class="mb-2">Nombre</p>
                      <v-text-field
                        v-model="formIngress.name"
                        class="pt-0"
                        placeholder="Nombre"
                        single-line
                        dense
                        outlined
                        :rules="nameRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <p class="mb-2">Detalle</p>
                      <v-text-field
                        v-model="formIngress.ingressDetail"
                        class="pt-0"
                        placeholder="Detalle"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loadingData"
                @click="close"
              >
                <span class="text-capitalize">Cancelar</span>
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                :loading="loadingData"
                @click="saveIngress"
              >
                <span class="text-capitalize">Guardar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <p style="color: #1874bf" class="text-h6 text-center pt-4">
              Informe de ingreso
            </p>
            <v-card-text v-if="user.idRol == 1" class="text-center">
              {{
                `¿Esta seguro de ${
                  editedItem.estado ? "desactivar" : "activar"
                } el tipo de ingreso ${editedItem.nombreTipoIngreso}?`
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
              <v-btn dark color="blue darken-1" @click="deleteItemConfirm"
                >Si</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <div v-show="search">
        <v-card outlined tile>
          <v-card-text class="mt-1">
            <p class="font-weight-bold text-body-1" style="color: #1ca5db">
              Buscar Ingreso
            </p>
            <v-form ref="formSearch">
              <v-row>
                <v-col cols="12" sm="4" md="4" class="py-0">
                  <p class="mb-2">Nombre</p>
                  <v-text-field
                    v-model="formSearch.name"
                    class="pt-0"
                    placeholder="Nombre"
                    single-line
                    dense
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-0">
                  <p class="mb-2">Total</p>
                  <v-text-field
                    v-model="formSearch.total"
                    class="pt-0"
                    placeholder="Total"
                    single-line
                    dense
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-0">
                  <p class="mb-2">Tipo</p>
                  <v-select
                    v-model="formSearch.idIngressType"
                    :items="types"
                    item-text="nombreTipoIngreso"
                    item-value="idTipoIngreso"
                    label="Seleccione una opcion"
                    persistent-hint
                    outlined
                    single-line
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4" md="4" class="py-0">
                  <p class="mb-2">Fecha Inicial</p>
                  <v-menu
                    ref="menuStart"
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formSearch.startDate"
                        placeholder="Fecha Inicial"
                        readonly
                        single-line
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formSearch.startDate"
                      no-title
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      min="2010-01-01"
                      @change="saveStart"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-0">
                  <p class="mb-2">Fecha Final</p>
                  <v-menu
                    ref="menuEnd"
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formSearch.endDate"
                        placeholder="Fecha Final"
                        readonly
                        single-line
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formSearch.endDate"
                      no-title
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      min="2010-01-01"
                      @change="saveEnd"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-0 d-flex align-center">
                  <v-row>
                    <v-btn
                      class="ml-2"
                      dark
                      color="#1CA5DB"
                      elevation="0"
                      @click="searchIngress"
                    >
                      <span class="text-capitalize text-body">Buscar</span>
                    </v-btn>
                    <v-spacer />
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="ingress.length > 0"
                          class="ml-3 mr-2"
                          color="primary"
                          fab
                          depressed
                          small
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="print"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </template>
                      <span>Imprimir Reporte</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="user.idRol == 1 && ingress.length > 0"
                          class="ml-3 mr-2"
                          color="#1D6F42"
                          fab
                          depressed
                          small
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="downloadXlsx"
                        >
                          <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </template>
                      <span>Descargar Excel</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
                <!--v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0"
								>
									<p class="mb-2">Apellidos</p>
									<v-text-field
										v-model="formSearchStudent.surnames"
										class="pt-0"
										placeholder="Apellidos"
										single-line
										dense
										outlined
									>
									</v-text-field>
								</v-col-->
                <!--v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0"
								>
									<p class="mb-2">Ci</p>
									<v-text-field
										v-model="formSearchStudent.ci"
										class="pt-0"
										placeholder="Ci"
										single-line
										dense
										outlined
									>
									</v-text-field>
								</v-col-->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="ingress.length > 0">
        <v-data-table
          :headers="headers"
          :items="ingress"
          item-key="idIngreso"
          class="elevation-1 font-weight-light caption"
          @click:row="clickRow"
          fixed-header
        >
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <template v-if="item.idReserva > 0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="grey"
                    class="mr-2"
                    @click.stop="assignClass(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-multiselect</v-icon>
                  </v-btn>
                </template>
                <span>Asignar Clases</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="yellow"
                    class="mr-2"
                    @click.stop="printClass(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Imprimir Clases</span>
              </v-tooltip>
            </template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="light-blue "
                  class="mr-2"
                  @click.stop="printItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="'error'"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ "mdi-delete" }}</v-icon>
                </v-btn>
              </template>
              <span>{{ "Eliminar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-card elevation="0" outlined v-if="loading == false">
          <v-card-text class="text-center pb-0"
            >No existen Tipos de ingreso Registrados.</v-card-text
          >
          <v-card-text class="text-center pt-0"
            ><v-btn
              color="blue darken-1"
              dark
              small
              class="my-2 px-2"
              tile
              elevation="0"
              @click="selectIngress"
              >Volver a Intentar</v-btn
            ></v-card-text
          >
        </v-card>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <ReceiptIngress
        ref="receipt"
        :item="itemReceipt"
        :user="user"
        :departament="nameDepartament"
        :lesson="ingressLessons"
      />
      <!--Ingress for practice-->
      <v-dialog v-model="dialogPractices" max-width="700px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formPracticeTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formPractice">
                <v-row>
                  <p class="font-weight-bold text-body-1">Buscar Profesor</p>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <p class="mb-2">Opciones</p>
                      <v-select
                        v-model="formSearchTeacher.option"
                        :items="optionItems"
                        item-text="option"
                        item-value="idOption"
                        label="Seleccione una opcion"
                        persistent-hint
                        return-object
                        outlined
                        single-line
                        dense
                        @change="optionTeacherChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" class="py-0">
                      <p class="mb-2">{{ optionName }}</p>
                      <v-text-field
                        v-model="formSearchTeacher.value"
                        class="pt-0"
                        :placeholder="optionName"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <v-btn
                        class="mt-sm-8 mt-md-8"
                        dark
                        color="#1CA5DB"
                        elevation="0"
                        @click="searchTeacherByParams"
                      >
                        <span class="text-capitalize text-body">Buscar</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <template v-if="loadingSearch">
                      <v-container class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-container>
                    </template>
                    <div v-show="searchTeachers.length > 0">
                      <v-data-table
                        :headers="headersTeachers"
                        :items="searchTeachers"
                        :items-per-page="-1"
                        class="elevation-1 font-weight-light caption td-click"
                        @click:row="clickRowSearchTeacher"
                        fixed-header
                        hide-default-footer
                      >
                      </v-data-table>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <div v-show="Object.keys(selectedTeacher).length != 0">
                      <p class="font-weight-bold text-body-1 mb-0 mt-2">
                        Informacion del Profesor de practica
                      </p>
                      <p
                        style="color: #4c4c4c"
                        class="mb-1 subtitle-2 font-weight-medium"
                      >
                        CI:
                        <span class="caption">{{ selectedTeacher.ci }}</span>
                      </p>
                      <p
                        style="color: #4c4c4c"
                        class="mb-1 subtitle-2 font-weight-medium"
                      >
                        Nombres y Apellidos:
                        <span class="caption">{{
                          selectedTeacher.nombres +
                          " " +
                          selectedTeacher.apellidos
                        }}</span>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Pago</p>
                    <v-text-field
                      v-model="formIngressPractice.total"
                      class="pt-0"
                      placeholder="Pago"
                      single-line
                      dense
                      outlined
                      :disabled="disabled"
                      :rules="paymentRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nivel</p>
                    <v-select
                      v-model="formIngressPractice.idLevel"
                      class="pt-0"
                      :items="lessonLevels"
                      item-text="nombreNivel"
                      item-value="idNivel"
                      placeholder="Nivel"
                      persistent-hint
                      single-line
                      dense
                      outlined
                      :rules="typeRules"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Nombre</p>
                    <v-text-field
                      v-model="formIngressPractice.name"
                      class="pt-0"
                      placeholder="Nombre"
                      single-line
                      dense
                      outlined
                      :rules="nameRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Detalle</p>
                    <v-text-field
                      v-model="formIngressPractice.ingressDetail"
                      class="pt-0"
                      placeholder="Detalle"
                      single-line
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeIngressPractice">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1 white--text"
              :disabled="Object.keys(selectedTeacher).length == 0"
              :loading="loadingData"
              @click="saveIngressPractice"
            >
              <span class="text-capitalize">Guardar Ingreso</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Ingress for packages-->
      <v-dialog v-model="dialogPackages" max-width="700px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formPackageTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formPackage">
                <v-row>
                  <p class="font-weight-bold text-body-1">Buscar Estudiante</p>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <p class="mb-2">Opciones</p>
                      <v-select
                        v-model="formSearchStudent.option"
                        :items="optionItems"
                        item-text="option"
                        item-value="idOption"
                        label="Seleccione una opcion"
                        persistent-hint
                        return-object
                        outlined
                        single-line
                        dense
                        @change="optionTeacherChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" class="py-0">
                      <p class="mb-2">{{ optionName }}</p>
                      <v-text-field
                        v-model="formSearchStudent.value"
                        class="pt-0"
                        :placeholder="optionName"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      class="py-0 d-flex align-center"
                    >
                      <div class="d-flex flex-row">
                        <v-btn
                          class=""
                          dark
                          color="#1CA5DB"
                          elevation="0"
                          @click="searchStudentByParams"
                        >
                          <span class="text-capitalize text-body">Buscar</span>
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          dark
                          color="primary"
                          elevation="0"
                          @click="dialogNewStudent = true"
                        >
                          <span class="text-capitalize text-body">Nuevo</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <template v-if="loadingSearch">
                      <v-container class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-container>
                    </template>
                    <div v-show="searchStudents.length > 0">
                      <v-data-table
                        :headers="headersTeachers"
                        :items="searchStudents"
                        :items-per-page="-1"
                        class="elevation-1 font-weight-light caption td-click"
                        @click:row="clickRowSearchTeacher"
                        fixed-header
                        hide-default-footer
                      >
                      </v-data-table>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0 mb-2">
                    <div v-show="Object.keys(selectedStudent).length != 0">
                      <p class="font-weight-bold text-body-1 mb-0 mt-2">
                        Informacion del Estudiante
                      </p>
                      <p
                        style="color: #4c4c4c"
                        class="mb-1 subtitle-2 font-weight-medium"
                      >
                        CI:
                        <span class="caption">{{ selectedStudent.ci }}</span>
                      </p>
                      <p
                        style="color: #4c4c4c"
                        class="mb-1 subtitle-2 font-weight-medium"
                      >
                        Nombres y Apellidos:
                        <span class="caption">{{
                          selectedStudent.nombres +
                          " " +
                          selectedStudent.apellidos
                        }}</span>
                      </p>
                    </div>
                  </v-col>
                  <div
                    class="container"
                    v-show="Object.keys(selectedStudent).length != 0"
                  >
                    <v-row>
                      <template v-if="editedIndex < 0">
                        <v-col cols="12" sm="6" md="6" class="py-0">
                          <p class="mb-2">Nivel</p>
                          <v-select
                            v-model="formIngressPractice.idLevel"
                            :items="lessonLevels"
                            item-text="nombreNivel"
                            item-value="idNivel"
                            label="Nivel"
                            persistent-hint
                            dense
                            outlined
                            single-line
                            :rules="levelRules"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-0">
                          <p class="mb-2">Nro Alumnos</p>
                          <v-select
                            v-model="formIngressPractice.numberStudents"
                            :items="numberStudents"
                            label="Nro Alumnos"
                            persistent-hint
                            dense
                            outlined
                            single-line
                            :rules="studentRules"
                          ></v-select>
                        </v-col>
                      </template>
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <p class="mb-2">Pago</p>
                        <v-text-field
                          v-model="formIngressPractice.total"
                          class="pt-0"
                          placeholder="Pago"
                          single-line
                          dense
                          outlined
                          :disabled="disabled"
                          :rules="paymentRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <p class="mb-2">Tipo</p>
                        <v-select
                          v-model="formIngressPractice.idIngressType"
                          class="pt-0"
                          :items="ingressTypes"
                          item-text="nombreTipoIngreso"
                          item-value="idTipoIngreso"
                          label="Seleccione una opcion"
                          persistent-hint
                          return-object
                          single-line
                          dense
                          outlined
                          :rules="typeRules"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="py-0">
                        <p class="mb-2">Nombre</p>
                        <v-text-field
                          v-model="formIngressPractice.name"
                          class="pt-0"
                          placeholder="Nombre"
                          single-line
                          dense
                          outlined
                          :rules="nameRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="py-0">
                        <p class="mb-2">Detalle</p>
                        <v-text-field
                          v-model="formIngressPractice.ingressDetail"
                          class="pt-0"
                          placeholder="Detalle"
                          single-line
                          dense
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeIngressPackage">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1 white--text"
              :disabled="Object.keys(selectedStudent).length == 0"
              :loading="loadingData"
              @click="saveIngressPackage"
            >
              <span class="text-capitalize">Guardar Ingreso</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogNewStudent" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Nuevo Estudiante" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formNewStudent">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nombres</p>
                    <v-text-field
                      v-model="formNewStudent.names"
                      class="pt-0"
                      label="Nombres"
                      placeholder="Nombres"
                      single-line
                      dense
                      outlined
                      autocomplete="null"
                      :rules="nameRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Apellidos</p>
                    <v-text-field
                      v-model="formNewStudent.surnames"
                      class="pt-0"
                      label="Apellidos"
                      placeholder="Apellidos"
                      single-line
                      dense
                      outlined
                      autocomplete="null"
                      :rules="surnameRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Ci</p>
                    <v-text-field
                      v-model="formNewStudent.ci"
                      class="pt-0"
                      label="Ci"
                      placeholder="Ci"
                      single-line
                      dense
                      outlined
                      autocomplete="null"
                      :rules="ciRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Telefono</p>
                    <v-text-field
                      v-model="formNewStudent.phone"
                      class="pt-0"
                      label="Telefono"
                      placeholder="Telefono"
                      single-line
                      dense
                      outlined
                      autocomplete="null"
                      :rules="phoneRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Email</p>
                    <v-text-field
                      v-model="formNewStudent.email"
                      class="pt-0"
                      label="Email"
                      placeholder="Email"
                      single-line
                      dense
                      outlined
                      autocomplete="null"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="py-0"
                    v-if="user.idRol == 1"
                  >
                    <p class="mb-2">Sucursal</p>
                    <v-select
                      v-model="formNewStudent.idBranchoffice"
                      :items="branchoffices"
                      item-text="nombreSucursal"
                      item-value="idSucursal"
                      label="Sucursal"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="branchofficeRules"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="loadingData"
              @click="closeStudent"
            >
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click="saveStudent"
            >
              <span class="text-capitalize">Guardar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTransfer" max-width="400px">
        <v-card>
          <p style="color: #1874bf" class="text-h6 text-center pt-4">
            Informe de Transferencia
          </p>
          <v-card-text class="text-center">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeTransfer">No</v-btn>
            <v-btn dark color="blue darken-1" @click="transferConfirm"
              >Si</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Assign class-->
      <v-dialog v-model="dialogAssignClass" max-width="700px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Seleccionar Docente" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formPackage">
                <v-row>
                  <p class="font-weight-bold text-body-1">Buscar Docente</p>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <p class="mb-2">Opciones</p>
                      <v-select
                        v-model="formSearchTeacher.option"
                        :items="optionItems"
                        item-text="option"
                        item-value="idOption"
                        label="Seleccione una opcion"
                        persistent-hint
                        return-object
                        outlined
                        single-line
                        dense
                        @change="optionTeacherChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" class="py-0">
                      <p class="mb-2">{{ optionName }}</p>
                      <v-text-field
                        v-model="formSearchTeacher.value"
                        class="pt-0"
                        :placeholder="optionName"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      class="py-0 d-flex align-center"
                    >
                      <div class="d-flex flex-row">
                        <v-btn
                          class=""
                          dark
                          color="#1CA5DB"
                          elevation="0"
                          @click="searchTeacherByParams"
                        >
                          <span class="text-capitalize text-body">Buscar</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <template v-if="loadingSearch">
                      <v-container class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-container>
                    </template>
                    <div v-show="searchTeachers.length > 0">
                      <v-data-table
                        :headers="headersTeachers"
                        :items="searchTeachers"
                        :items-per-page="-1"
                        class="elevation-1 font-weight-light caption td-click"
                        @click:row="clickRowAssignClass"
                        fixed-header
                        hide-default-footer
                      >
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAssignClass">
              <span class="text-capitalize">Cerrar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DialogDownload :dialog="dialogDownload" />
      <ReportGeneralIngress
        ref="reportGeneralIngress"
        @downloadStop="dialogDownloadStop"
        :user="user"
        :ingress="ingress"
        :departament="nameDepartament"
        :details="detailsComponent"
        :totalIngress="totalIngress"
      />
    </v-container>
  </v-layout>
</template>

<script>
import ReceiptIngress from "@/components/receipt/receipt-ingress-component.vue";
import DialogDownload from "@/components/dialog/dialog-download-component.vue";
import ReportGeneralIngress from "@/components/report/report-general-ingress-component.vue";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import * as XLSX from "xlsx";
export default {
  components: {
    ReceiptIngress,
    DialogDownload,
    ReportGeneralIngress,
  },
  data() {
    return {
      dialog: false,
      dialogPackages: false,
      dialogPractices: false,
      dialogDelete: false,
      headers: [
        {
          text: "Nro Ingreso",
          value: "codigo",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombre",
          value: "nombre",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Total Bs.",
          value: "total",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Tipo",
          value: "nombreTipoIngreso",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Detalle",
          value: "detalleIngreso",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      ingress: [],
      ingressTypes: [],
      editedIndex: -1,
      idType: "",
      nameIngressType: "",
      description: "",
      idIngressType: "",
      creationDate: new Date().toISOString().substring(0, 10),
      formIngress: {
        idIngress: "",
        paymentDate: "",
        name: "",
        ingressDetail: "",
        total: "",
        idIngressType: "",
        idReservation: "",
      },
      nameRules: [(value) => !!value || "Ingrese un Nombre"],
      typeRules: [(value) => !!value || "Ingrese un Tipo"],
      paymentRules: [(value) => !!value || "Ingrese un Pago"],
      loading: false,
      loadingData: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      editedItem: {},
      defaultItem: {},
      items: [
        {
          text: "Ingreso",
          disabled: true,
          href: "turn",
        },
      ],
      typeItems: [
        {
          idType: 1,
          nameType: "INGRESO",
        },
        {
          idType: 2,
          nameType: "PAQUETE",
        },
        {
          idType: 3,
          nameType: "PRACTICA",
        },
      ],
      option: 0,
      itemReceipt: {},
      //Ingress for practice
      optionItems: [
        {
          idOption: 1,
          option: "CI",
        },
        {
          idOption: 2,
          option: "Apellidos",
        },
        {
          idOption: 3,
          option: "Nombres",
        },
      ],
      optionName: "CI",
      formSearchTeacher: {
        names: "",
        surnames: "",
        ci: "",
        value: "",
        option: {
          idOption: 1,
          option: "CI",
        },
      },
      formIngressPractice: {
        idIngress: "",
        paymentDate: "",
        name: "",
        ingressDetail: "",
        total: "",
        idIngressType: "",
        idReservation: "",
        idLevel: "",
        idPracticeTeacher: "",
        numberStudents: "",
      },
      numberStudents: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      levelRules: [(value) => !!value || "Debe seleccionar un nivel"],
      studentRules: [
        (value) => !!value || "Debe seleccionar la cantidad de estudiantes",
      ],
      searchTeachers: [],
      headersTeachers: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
      ],
      selectedTeacher: {},
      loadingSearch: false,
      lessonLevels: [],
      //ingress for packages
      searchStudents: [],
      formSearchStudent: {
        names: "",
        surnames: "",
        ci: "",
        value: "",
        option: {
          idOption: 1,
          option: "CI",
        },
      },
      selectedStudent: {},
      ingressLessons: [],
      //advanced search
      search: false,
      formSearch: {
        name: "",
        total: "",
        idIngressType: "",
        startDate: "",
        endDate: "",
      },
      types: [],
      activePicker: null,
      date: null,
      menuStart: false,
      menuEnd: false,
      //ingress schedule class
      dialogAssignClass: false,
      nameDepartament: "",
      //new student
      formNewStudent: {
        idStudent: "",
        names: "",
        surnames: "",
        ci: "",
        email: "",
        phone: "",
        idBranchoffice: "",
        creationDate: new Date().toISOString().substring(0, 10),
      },
      surnameRules: [(value) => !!value || "Ingrese apellidos"],
      ciRules: [(value) => !!value || "Ingrese un ci"],
      emailRules: [
        (value) => !!value || "Ingrese un email",
        (value) => /.+@.+\..+/.test(value) || "Debe ingresar un email correcto",
      ],
      phoneRules: [
        (value) => !!value || "Ingrese un numero de telefono",
        (value) =>
          /^(\d{8}-\d{8}|\d{8})$/.test(value) ||
          "Debe ingresar un formato correcto",
      ],
      branchofficeRules: [(value) => !!value || "Seleccione una sucursal"],
      branchoffices: [],
      dialogNewStudent: false,
      dialogTransfer: false,
      userData: {},
      message: "",
      idBranchofficeTransfer: "",
      disabled: false,
      dialogDownload: false,
      detailsComponent: {},
      totalIngress: 0,
    };
  },
  computed: {
    ...mapState(["user", "idDepartament"]),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Ingreso" : "Editar Ingreso";
    },
    formPracticeTitle() {
      return this.editedIndex === -1
        ? "Agregar Ingreso por Practica"
        : "Editar Ingreso por Practica";
    },
    formPackageTitle() {
      return this.editedIndex === -1
        ? "Agregar Ingreso por Paquete"
        : "Editar Ingreso por Paquete";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPractices(val) {
      val || this.closeIngressPractice();
    },
    dialogPackages(val) {
      val || this.closeIngressPackage();
    },
    menuStart(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuEnd(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    dialogNewStudent(val) {
      val || this.closeStudent();
    },
  },
  created() {
    //this.selectIngressTypes();
    this.user.idRol == 1
      ? this.selectDepartamentById()
      : (this.nameDepartament = this.user.departamento);
    this.selectIngress();
    this.selectTypes();
    this.selectLessonLevels();
    this.selectBranchoffices();
  },
  methods: {
    ...mapMutations(["setStudentData"]),
    dialogDownloadStop() {
      this.dialogDownload = false;
    },
    //print data
    print() {
      this.dialogDownload = true;
      this.$refs.reportGeneralIngress.generatePdf();
    },
    //export excel
    downloadXlsx() {
      this.dialogDownload = true;
      let wb = XLSX.utils.book_new();
      if (this.ingress.length > 0) {
        const data = XLSX.utils.json_to_sheet(this.ingress, {
          header: [
            "codigo",
            "nombre",
            "total",
            "nombreTipoIngreso",
            "detalleIngreso",
          ],
        });
        data["A1"].v = "Nro Ingreso";
        data["B1"].v = "Nombre";
        data["C1"].v = "Total Bs";
        data["D1"].v = "Tipo";
        data["E1"].v = "Detalle";
        XLSX.utils.book_append_sheet(wb, data, "Ingresos");
      }

      XLSX.writeFile(wb, "ReporteIngresos.xlsx");
      this.dialogDownload = false;
    },
    //transferStudent
    async updateTransferUser(data) {
      try {
        let response = await axios.put("users/updateTransferUser", data);
        if (response.status == 200) {
          this.searchStudents.push(this.userData);
          this.closeTransfer();
          this.notification("success", response.data["msg"]);
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    transferConfirm() {
      let data = {
        idBranchoffice: this.idBranchofficeTransfer,
        idUser: this.userData.idUsuario,
      };

      this.updateTransferUser(data);
    },
    closeTransfer() {
      this.dialogTransfer = false;
      this.userData = {};
      this.message = "";
      this.idBranchofficeTransfer = "";
    },
    //Module insert student
    async insertStudent(data) {
      this.searchStudents = [];
      try {
        let response = await axios.post("users/insertStudent", data);
        if (response.status == 200) {
          if (
            response.data.type == 0 ||
            response.data.type == 1 ||
            response.data.type == 2
          ) {
            this.closeStudent();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.formSearchStudent.value = "";
            if (response.data.type != 1) {
              this.searchStudents.push(response.data["data"]);
            }
          } else {
            this.idBranchofficeTransfer = this.formNewStudent.idBranchoffice;
            this.closeStudent();
            this.loadingData = false;
            this.userData = response.data.data;
            this.message = response.data.msg;
            this.dialogTransfer = true;
            this.formSearchStudent.value = "";
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveStudent() {
      if (this.$refs.formNewStudent.validate()) {
        let data = {
          names: this.formNewStudent.names.toUpperCase().trim(),
          surnames: this.formNewStudent.surnames.toUpperCase().trim(),
          ci: this.formNewStudent.ci.trim(),
          email: this.formNewStudent.email.trim(),
          phone: this.formNewStudent.phone.trim(),
          idBranchoffice:
            this.user.idRol == 1
              ? this.formNewStudent.idBranchoffice
              : this.user.idSucursal,
          user: null,
          pass: null,
          idDepartament: this.idDepartament,
        };
        this.loadingData = true;

        this.insertStudent(data);
      }
    },
    closeStudent() {
      this.dialogNewStudent = false;
      this.formNewStudent.ci = "";
      this.formNewStudent.email = "";
      this.formNewStudent.idBranchoffice = "";
      this.formNewStudent.idStudent = "";
      this.formNewStudent.names = "";
      this.formNewStudent.phone = "";
      this.formNewStudent.surnames = "";
      this.$refs.formNewStudent.resetValidation();
    },
    async selectBranchoffices() {
      let url = `branchoffices/selectBranchoffices/${this.idDepartament}/1`;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          this.branchoffices = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectDepartamentById() {
      try {
        let response = await axios.get(
          `departaments/selectDepartamentById/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.nameDepartament = response.data.nombreDepartamento;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //printClass
    async selectIngressLessonsByIdReservation(idReservation) {
      try {
        this.ingressLessons = [];
        let response = await axios.get(
          `lessons/selectIngressLessonsByIdReservation/${idReservation}`
        );
        if (response.status == 200) {
          response.data.map((value) => {
            this.ingressLessons.push({
              idClase: value.idClase,
              fecha: this.formatDateTimeClass(
                value.fechaInicial,
                value.fechaFinal
              ),
              docente: value.nombres + " " + value.apellidos,
              materia: value.nombreMateria,
              virtual: value.virtual ? "Virtual" : "Presencial",
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async printClass(item) {
      const date = this.formatDateTimeReceipt(item.fechaPago);
      this.itemReceipt = item;
      this.itemReceipt.fechaPago = date;
      await this.selectIngressLessonsByIdReservation(item.idReserva);
      this.$refs.receipt.generatePdf();
    },
    formatDateTimeClass(start, end) {
      if (!start || !end) return null;
      let dateSub = start.substring(0, 10);
      let timeStart = start.substring(11, 16);
      let timeEnd = end.substring(11, 16);
      const [year, month, day] = dateSub.split("-");
      return `${day}-${month}-${year} (${timeStart} - ${timeEnd})`;
    },
    //ingress schedule class
    async assignClass(item) {
      this.editedItem = Object.assign({}, item);
      await this.selectStudentByIdReservation(item.idReserva);
      if (this.selectedStudent.hrsRestante == 0 && this.user.idRol != 1) {
        this.notification("info", "Ya se asignaron todas las clases");
        return;
      }
      this.dialogAssignClass = true;
    },
    clickRowAssignClass(item) {
      const student = {
        apellidos: this.selectedStudent.apellidos,
        ci: this.selectedStudent.ci,
        hrsRestante: this.selectedStudent.hrsRestante,
        idLevel: this.selectedStudent.idNivel,
        idReserva: this.selectedStudent.idReserva,
        idUsuario: this.selectedStudent.idUsuario,
        nombres: this.selectedStudent.nombres,
        numberStudents: this.selectedStudent.nroAlumnos,
        sigla: this.selectedStudent.sigla,
      };
      this.setStudentData(student);
      this.$router.push(`/ingress/teacher/${item.idUsuario}/schedule`);
    },
    closeAssignClass() {
      this.dialogAssignClass = false;
      this.formSearchTeacher.ci = "";
      this.formSearchTeacher.names = "";
      this.formSearchTeacher.option = { idOption: 1, option: "CI" };
      this.formSearchTeacher.surnames = "";
      this.formSearchTeacher.value = "";
      this.searchTeachers = [];
      this.editedItem = {};
      this.editedIndex = -1;
      this.selectedStudent = "";
    },
    //module advanced search
    openAdvancedSearch() {
      this.search = !this.search;
      if (this.search == false) {
        this.formSearch.endDate = "";
        this.formSearch.idIngressType = "";
        this.formSearch.name = "";
        this.formSearch.startDate = "";
        this.formSearch.total = "";
        this.selectIngress();
        this.ingress = [];
      }
    },
    async selectSearchIngress(url) {
      try {
        this.ingress = [];
        this.totalIngress = 0;
        this.loading = true;
        let response = await axios.get(`ingress/selectSearchIngress/${url}`);
        if (response.status == 200) {
          response.data.map((value, index) => {
            value.nro = index + 1;
            this.totalIngress = this.totalIngress + Number(value.total);
          });
          this.ingress = response.data;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro coincidencias");
          }
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loading = false;
      }
    },
    searchIngress() {
      let name = this.formSearch.name == "" ? "null" : this.formSearch.name;
      let total = this.formSearch.total == "" ? "null" : this.formSearch.total;
      let idIngressType =
        this.formSearch.idIngressType == ""
          ? "null"
          : this.formSearch.idIngressType;
      let startDate =
        this.formSearch.startDate == "" ? "null" : this.formSearch.startDate;
      let endDate =
        this.formSearch.endDate == "" ? "null" : this.formSearch.endDate;
      let url = `${this.idDepartament}/${name}/${total}/${idIngressType}/${startDate}/${endDate}`;

      this.selectSearchIngress(url);
    },
    saveStart(date) {
      this.$refs.menuStart.save(date);
      delete this.detailsComponent.endDate;
      delete this.detailsComponent.startDate;
      this.detailsComponent = {
        ...this.detailsComponent,
        startDate: date,
      };
    },
    saveEnd(date) {
      this.$refs.menuEnd.save(date);
      delete this.detailsComponent.endDate;
      this.detailsComponent = {
        ...this.detailsComponent,
        endDate: date,
      };
    },
    async selectTypes() {
      try {
        let response = await axios.get(
          `ingressTypes/selectIngressTypes/1/null/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.types = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //module ingress for packages
    async updateIngressPackage(data) {
      try {
        let response = await axios.put("ingress/updateIngressPackage", data);
        if (response.status == 200) {
          this.closeIngressPackage();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertIngressPackage(data) {
      try {
        let response = await axios.post("ingress/insertIngressPackage", data);
        if (response.status == 200) {
          this.closeIngressPackage();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.printItem(response.data.data);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveIngressPackage() {
      if (this.$refs.formPackage.validate()) {
        if (this.editedIndex > -1) {
          this.loadingData = true;
          let data = {
            name: this.formIngressPractice.name.trim().toUpperCase(),
            ingressDetail: this.formIngressPractice.ingressDetail
              .trim()
              .toUpperCase(),
            total: this.formIngressPractice.total,
            idIngressType:
              Object.keys(this.formIngressPractice.idIngressType).length == 0
                ? this.formIngressPractice.idIngressType
                : this.formIngressPractice.idIngressType.idTipoIngreso,
            idIngress: this.formIngressPractice.idIngress,
            idStudent: this.selectedStudent.idUsuario,
            idReservation: this.formIngressPractice.idReservation,
          };

          this.updateIngressPackage(data);
        } else {
          this.loadingData = true;
          const typeIngress = this.types.find(
            (value) =>
              value.idTipoIngreso == this.formIngressPractice.idIngressType
          );
          const hours = typeIngress.nombreTipoIngreso.match(/\d+/);
          let data = {
            name: this.formIngressPractice.name.trim().toUpperCase(),
            ingressDetail: this.formIngressPractice.ingressDetail
              .trim()
              .toUpperCase(),
            total: this.formIngressPractice.total,
            idIngressType: this.formIngressPractice.idIngressType,
            idUser: this.user.idUsuario,
            idDepartament:
              this.user.idRol == 1
                ? this.idDepartament
                : this.user.idDepartamento,
            idStudent: this.selectedStudent.idUsuario,
            numberStudents: this.formIngressPractice.numberStudents,
            idLevel: this.formIngressPractice.idLevel,
            hoursLeft: hours[0],
          };

          this.insertIngressPackage(data);
        }
      }
    },
    searchStudentByParams() {
      let names = "";
      let surnames = "";
      let ci = "";
      if (this.formSearchStudent.option.idOption == 1) {
        names = null;
        surnames = null;
        ci =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
      } else if (this.formSearchStudent.option.idOption == 2) {
        names = null;
        surnames =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
        ci = null;
      } else if (this.formSearchStudent.option.idOption == 3) {
        names =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
        surnames = null;
        ci = null;
      }
      let url = `${this.idDepartament}/${names}/${surnames}/${ci}`;
      this.loadingSearch = true;
      this.selectSearchStudent(url);
    },
    async selectSearchStudent(url) {
      try {
        let response = await axios.get(`users/selectSearchStudentClass/${url}`);
        if (response.status == 200) {
          this.searchStudents = response.data;
          this.loadingSearch = false;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingSearch = false;
      }
    },
    async selectStudentByIdReservation(id) {
      try {
        let response = await axios.get(
          `ingress/selectStudentByIdReservation/${id}`
        );
        if (response.status == 200) {
          this.selectedStudent = response.data;
          this.formIngressPractice.idReservation = response.data.idReserva;
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeIngressPackage() {
      this.dialogPackages = false;
      this.disabled = false;
      this.$refs.formPackage.resetValidation();
      this.formIngressPractice.idIngress = "";
      this.formIngressPractice.idIngressType = "";
      this.formIngressPractice.idLevel = "";
      this.formIngressPractice.idReservation = "";
      this.formIngressPractice.ingressDetail = "";
      this.formIngressPractice.name = "";
      this.formIngressPractice.paymentDate = "";
      this.formIngressPractice.total = "";
      this.formIngressPractice.numberStudents = "";
      this.selectedStudent = {};
      this.searchStudents = [];
      this.editedIndex = -1;
    },

    //module ingress for practice
    optionTeacherChange(evt) {
      this.optionName = evt.option;
      this.formSearchTeacher.value = "";
      this.formSearchStudent.value = "";
    },
    clickRowSearchTeacher(item) {
      if (this.option == 2) {
        this.selectedStudent = Object.assign({}, item, {});
        this.formIngressPractice.name = item.nombres + " " + item.apellidos;
        this.searchStudents = [];
      } else if (this.option == 3) {
        this.selectedTeacher = Object.assign({}, item, {});
        this.searchTeachers = [];
      }
      //this.closeTeacherClass();
    },
    async updateIngressPractice(data) {
      try {
        let response = await axios.put("ingress/updatePracticeTeacher", data);
        if (response.status == 200) {
          this.closeIngressPractice();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertIngressPractice(data) {
      try {
        let response = await axios.post("ingress/insertPracticeTeacher", data);
        if (response.status == 200) {
          this.closeIngressPractice();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.printItem(response.data.data);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveIngressPractice() {
      if (this.$refs.formPractice.validate()) {
        if (this.editedIndex > -1) {
          this.loadingData = true;
          let data = {
            name: this.formIngressPractice.name.trim().toUpperCase(),
            ingressDetail: this.formIngressPractice.ingressDetail
              .trim()
              .toUpperCase(),
            total: this.formIngressPractice.total,
            idIngressType: this.formIngressPractice.idIngressType,
            idIngress: this.formIngressPractice.idIngress,
            idTeacher: this.selectedTeacher.idUsuario,
            idLevel: this.formIngressPractice.idLevel,
            idPracticeTeacher: this.formIngressPractice.idPracticeTeacher,
          };

          this.updateIngressPractice(data);
        } else {
          this.loadingData = true;
          let data = {
            name: this.formIngressPractice.name.trim().toUpperCase(),
            ingressDetail: this.formIngressPractice.ingressDetail
              .trim()
              .toUpperCase(),
            total: this.formIngressPractice.total,
            idIngressType: this.formIngressPractice.idIngressType,
            idUser: this.user.idUsuario,
            idDepartament:
              this.user.idRol == 1
                ? this.idDepartament
                : this.user.idDepartamento,
            idTeacher: this.selectedTeacher.idUsuario,
            idLevel: this.formIngressPractice.idLevel,
          };

          this.insertIngressPractice(data);
        }
      }
    },
    searchTeacherByParams() {
      let names = "";
      let surnames = "";
      let ci = "";
      this.selectedTeacher = {};
      if (this.formSearchTeacher.option.idOption == 1) {
        names = null;
        surnames = null;
        ci =
          this.formSearchTeacher.value == ""
            ? null
            : this.formSearchTeacher.value;
      } else if (this.formSearchTeacher.option.idOption == 2) {
        names = null;
        surnames =
          this.formSearchTeacher.value == ""
            ? null
            : this.formSearchTeacher.value;
        ci = null;
      } else if (this.formSearchTeacher.option.idOption == 3) {
        names =
          this.formSearchTeacher.value == ""
            ? null
            : this.formSearchTeacher.value;
        surnames = null;
        ci = null;
      }
      let url = `${this.idDepartament}/null/${names}/${surnames}/${ci}`;
      this.loadingSearch = true;
      this.selectSearchTeacher(url);
    },
    async selectSearchTeacher(url) {
      try {
        let response = await axios.get(`users/selectSearchTeacher/${url}`);
        if (response.status == 200) {
          this.searchTeachers = response.data;
          this.loadingSearch = false;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingSearch = false;
      }
    },
    async selectPracticeTeacherByIdIngress(id) {
      try {
        let response = await axios.get(
          `ingress/selectPracticeTeacherByIdIngress/${id}`
        );
        if (response.status == 200) {
          this.selectedTeacher = response.data;
          this.formIngressPractice.idLevel = response.data.idNivel;
          this.formIngressPractice.idPracticeTeacher =
            response.data.idDocentePractica;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectLessonLevels() {
      try {
        let response = await axios.get(
          `lessonLevels/selectLessonLevels/1/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.lessonLevels = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeIngressPractice() {
      this.dialogPractices = false;
      this.disabled = false;
      this.formIngressPractice.idIngress = "";
      this.formIngressPractice.idIngressType = "";
      this.formIngressPractice.idLevel = "";
      this.formIngressPractice.idReservation = "";
      this.formIngressPractice.ingressDetail = "";
      this.formIngressPractice.name = "";
      this.formIngressPractice.paymentDate = "";
      this.formIngressPractice.total = "";
      this.selectedTeacher = {};
      this.$refs.formPractice.resetValidation();
      this.searchTeachers = [];
      this.editedIndex = -1;
    },

    //module ingress
    printItem(item) {
      this.ingressLessons = [];
      const date = this.formatDateTimeReceipt(item.fechaPago);
      this.itemReceipt = item;
      this.itemReceipt.fechaPago = date;
      this.$refs.receipt.generatePdf();
    },
    async insertIngress(data) {
      try {
        let response = await axios.post("ingress/insertIngress", data);
        if (response.status == 200) {
          this.close();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.printItem(response.data.data);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async updateIngress(data) {
      try {
        let response = await axios.put("ingress/updateIngress", data);
        if (response.status == 200) {
          this.close();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async deleteIngress(data) {
      try {
        let response = await axios.put("ingress/deleteIngress", data);
        if (response.status == 200) {
          this.closeDelete();
          this.notification("warning", response.data["msg"]);
          this.selectIngress();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async openDialog(option) {
      if (option == 1) {
        this.option = option;
        this.dialog = true;
        await this.selectIngressTypes(option);
      } else if (option == 2) {
        this.option = option;
        this.dialogPackages = true;
        await this.selectIngressTypes(option);
      } else if (option == 3) {
        this.option = option;
        this.dialogPractices = true;
        await this.selectIngressTypes(option);
      }
    },
    saveIngress() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          //modificar
          let data = {
            name: this.formIngress.name.trim().toUpperCase(),
            ingressDetail: this.formIngress.ingressDetail.trim().toUpperCase(),
            total: this.formIngress.total,
            idIngressType: this.formIngress.idIngressType,
            idIngress: this.formIngress.idIngress,
          };
          this.loadingData = true;
          this.updateIngress(data);
        } else {
          let data = {
            name: this.formIngress.name.trim().toUpperCase(),
            ingressDetail: this.formIngress.ingressDetail.trim().toUpperCase(),
            total: this.formIngress.total,
            idIngressType: this.formIngress.idIngressType,
            idUser: this.user.idUsuario,
            idDepartament:
              this.user.rol == 1
                ? this.idDepartament
                : this.user.idDepartamento,
          };
          this.loadingData = true;

          this.insertIngress(data);
        }
      }
    },

    clickRow(item) {
      console.log(item);
      //this.$router.push('/group/'+item.idSemestre);
    },

    async selectIngress() {
      const id =
        this.user.idRol == 1 ? this.idDepartament : this.user.idDepartamento;
      this.totalIngress = 0;
      try {
        this.loading = true;
        let response = await axios.get(`ingress/selectIngress/${id}`);
        if (response.status == 200) {
          response.data.map((value, index) => {
            value.nro = index + 1;
            this.totalIngress = this.totalIngress + Number(value.total);
          });
          this.ingress = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async selectIngressTypes(option) {
      try {
        this.ingressTypes = [];
        let response = await axios.get(
          `ingressTypes/selectIngressTypes/1/${option}/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.ingressTypes = response.data;
          if (response.data.length == 1) {
            this.formIngressPractice.idIngressType =
              response.data[0].idTipoIngreso;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },

    async editItem(item) {
      this.option = item.tipo;
      if (this.option == 1) {
        this.disabled = this.user.idRol == 1 ? false : true;
        this.editedIndex = 1;
        this.editedItem = Object.assign({}, item);
        this.formIngress.idIngress = item.idIngreso;
        this.formIngress.idIngressType = item.idTipoIngreso;
        this.formIngress.ingressDetail = item.detalleIngreso;
        this.formIngress.name = item.nombre;
        this.formIngress.paymentDate = item.fechaPago;
        this.formIngress.total = item.total;
        this.ingressTypes = [];
        await this.selectIngressTypes(this.option);
        this.dialog = true;
      } else if (this.option == 2) {
        this.disabled = this.user.idRol == 1 ? false : true;
        this.editedIndex = 1;
        this.editedItem = Object.assign({}, item);
        this.formIngressPractice.idIngress = item.idIngreso;
        this.formIngressPractice.idIngressType = item.idTipoIngreso;
        this.formIngressPractice.ingressDetail = item.detalleIngreso;
        this.formIngressPractice.name = item.nombre;
        this.formIngressPractice.paymentDate = item.fechaPago;
        this.formIngressPractice.total = item.total;
        this.ingressTypes = [];
        await this.selectIngressTypes(this.option);
        await this.selectStudentByIdReservation(item.idReserva);
        this.dialogPackages = true;
      } else if (this.option == 3) {
        this.disabled = this.user.idRol == 1 ? false : true;
        this.editedIndex = 1;
        this.editedItem = Object.assign({}, item);
        this.formIngressPractice.idIngress = item.idIngreso;
        this.formIngressPractice.idIngressType = item.idTipoIngreso;
        this.formIngressPractice.ingressDetail = item.detalleIngreso;
        this.formIngressPractice.name = item.nombre;
        this.formIngressPractice.paymentDate = item.fechaPago;
        this.formIngressPractice.total = item.total;
        this.ingressTypes = [];
        await this.selectIngressTypes(this.option);
        await this.selectPracticeTeacherByIdIngress(item.idIngreso);
        this.dialogPractices = true;
      }
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let data = {
        idIngress: this.editedItem.idIngreso,
        state: this.editedItem.estado ? 0 : 1,
      };
      this.deleteIngress(data);
    },

    close() {
      if (this.option == 1) {
        this.disabled = false;
        this.editedIndex = -1;
        this.$refs.form.resetValidation();
        this.dialog = false;
        this.formIngress.idIngress = "";
        this.formIngress.idIngressType = "";
        this.formIngress.idReservation = "";
        this.formIngress.ingressDetail = "";
        this.formIngress.name = "";
        this.formIngress.paymentDate = "";
        this.formIngress.total = "";
        this.option = 0;
        this.ingressTypes = [];
      } else if (this.option == 2) {
        this.disabled = false;
        this.option = 0;
      } else if (this.option == 3) {
        this.disabled = false;
        this.option = 0;
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    formatDateTimeReceipt(date) {
      if (!date) return null;
      let dateSub = date.substring(0, 10);
      let timeSub = date.substring(11, 19);
      const [year, month, day] = dateSub.split("-");
      return `${day}-${month}-${year} ${timeSub}`;
    },
  },
};
</script>

<style>
.td-click td {
  cursor: pointer;
}
</style>