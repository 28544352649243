import Vue from "vue";
import Vuex from "vuex";

import decode from "jwt-decode";
import router from "../router/index";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		token: null,
		user: null,
		expiration: null,
		session: false,
		drawer: false,
		appIcon: false,
		idDepartament: null,
		studentData: null,
		optionsGroup: [],
		optionsSchedule: [],
		optionsAdmin: [],
		optionsIngress: [],
		optionsReport: [],
		optionsStudentGrade: [],
		optionsStudentCredential: [],
		pageTable: 1,
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
		setUser(state, user) {
			state.user = user;
		},
		setExpiration(state, value) {
			state.expiration = value;
		},
		setSession(state, value) {
			state.session = value;
		},
		setDrawer(state, value) {
			state.drawer = value;
		},
		setAppIcon(state, value) {
			state.appIcon = value;
		},
		setIdDepartament(state, value) {
			state.idDepartament = value;
		},
		setStudentData(state, value) {
			state.studentData = value;
		},
		setOptionsGroup(state, value) {
			state.optionsGroup = value;
		},
		setOptionsSchedule(state, value) {
			state.optionsSchedule = value;
		},
		setOptionsAdmin(state, value) {
			state.optionsAdmin = value;
		},
		setOptionsReport(state, value) {
			state.optionsReport = value;
		},
		setOptionsIngress(state, value) {
			state.optionsIngress = value;
		},
		setOptionsStudentGrade(state, value) {
			state.optionsStudentGrade = value;
		},
		setOptionsStudentCredential(state, value) {
			state.optionsStudentCredential = value;
		},
		setPageTable(state, value) {
			state.pageTable = value;
		},
	},
	actions: {
		saveToken({ commit }, token) {
			commit("setToken", token);
			commit("setUser", decode(token));
			let user = decode(token);
			localStorage.setItem("token", token);
			localStorage.setItem("idUsuario", user.idUsuario);
		},
		saveExpiration({ commit }, expiration) {
			commit("setExpiration", expiration);
			localStorage.setItem("expiration", expiration);
		},
		autoLogin({ commit }) {
			let token = localStorage.getItem("token");
			if (token) {
				commit("setToken", token);
				commit("setUser", decode(token));
				commit("setSession", true);

				if (this.state.user.idRol == 1) {
					router.replace({ name: "home" });
				} else if (this.state.user.idRol == 2) {
					commit("setIdDepartament", this.state.user.idDepartamento);
					commit("setAppIcon", true);
					router.replace({ name: "home-administrative" });
				} else if (
					this.state.user.idRol > 2 &&
					this.state.user.idRol < 6
				) {
					commit("setIdDepartament", this.state.user.idDepartamento);
					commit("setAppIcon", true);
					router.replace({ name: "subject-schedule" });
				}
			}
		},
		logout({ commit }) {
			commit("setToken", null);
			commit("setUser", null);
			commit("setExpiration", null);
			commit("setDrawer", false);
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			localStorage.removeItem("expiration");
			router.replace({ name: "login" });
		},
	},
	modules: {},
});

export default store;
export const useStore = () => store;
