<template>
  <v-layout>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card outlined>
        <v-card-text>
          <p class="text-h6 mb-0">Calificaciones</p>
          <v-container>
            <v-form ref="formGradeSearch">
              <v-row class="d-flex flex-row">
                <v-col cols="12" sm="2" md="2" class="py-0">
                  <p class="mb-2">Semestre</p>
                  <v-autocomplete
                    v-model="formGroup.idSemester"
                    :items="semesters"
                    :filter="semesterFilter"
                    outlined
                    dense
                    item-text="nombreSemestre"
                    item-value="idSemestre"
                    label="Seleccione"
                    single-line
                    @change="findGroup"
                    :rules="semesterRules"
                    no-data-text="No hay semestres disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  <p class="mb-2">Grupo</p>
                  <v-autocomplete
                    v-model="formGroup.idGroup"
                    :items="groups"
                    :filter="groupFilter"
                    outlined
                    dense
                    item-text="nombreGrupo"
                    item-value="idGrupo"
                    label="Seleccione"
                    single-line
                    @change="findSubject"
                    :rules="groupRules"
                    no-data-text="No hay grupos disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  <p class="mb-2">Mes</p>
                  <v-select
                    v-model="formGroup.month"
                    :items="months"
                    item-text="name"
                    item-value="value"
                    label="Seleccione"
                    persistent-hint
                    dense
                    outlined
                    single-line
                    @change="changeMonth"
                    :rules="monthRules"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  <p class="mb-2">Tipo Evaluación</p>
                  <v-select
                    v-model="formGroup.type"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    label="Seleccione"
                    persistent-hint
                    dense
                    outlined
                    single-line
                    @change="changeType"
                    :rules="typeRules"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-0" align-self="center">
                  <v-row justify="space-between">
                    <v-btn
                      color="#1486bd"
                      dark
                      elevation="0"
                      :loading="loading"
                      @click="findStudents"
                    >
                      <span class="text-capitalize text-body"
                        >Buscar Grupo</span
                      >
                    </v-btn>
                    <div>
                      <v-row class="ma-0 pa-0">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-show="students.length > 0"
                              class="ml-3 mr-2"
                              color="#1D6F42"
                              fab
                              depressed
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="downloadXlsx"
                            >
                              <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar Lista</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-show="students.length > 0"
                              class="ml-3 mr-2"
                              color="red"
                              fab
                              depressed
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="uploadXlsx"
                            >
                              <v-icon>mdi-table-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <span>Subir Notas</span>
                        </v-tooltip>
                        <input
                          v-show="false"
                          ref="uploadInput"
                          type="file"
                          @change="uploadFile"
                          accept=".xlsx .xls"
                        />
                      </v-row>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>

      <template v-if="students.length > 0">
        <!--v-simple-table class="elevation-1 font-weight-light caption">
          <thead class="font-weight-bold blue-grey lighten-5">
            <tr>
              <!- Encabezado combinado para dos columnas ->
              <th colspan="1"></th>
              <th
                v-for="subject in subjects"
                :key="subject.idMateria"
                colspan="2"
                class="text-center"
              >
                {{ subject.codigoMateria }}
              </th>
            </tr>
            <tr>
              <th>Nombres y Apellidos</th>
              <th class="text-center">Simulacro</th>
              <th class="text-center">Examen</th>
              <th class="text-center">Simulacro</th>
              <th class="text-center">Examen</th>
              <th class="text-center">Simulacro</th>
              <th class="text-center">Examen</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Juan Pérez</td>
              <td class="text-center">28</td>
              <td class="text-center">35</td>
              <td class="text-center">35</td>
              <td class="text-center">45</td>
              <td class="text-center">35</td>
              <td class="text-center">45</td>
            </tr>

            <tr>
              <td>Ana Lopez</td>
              <td class="text-center">28</td>
              <td class="text-center">35</td>
              <td class="text-center">35</td>
              <td class="text-center">45</td>
              <td class="text-center">35</td>
              <td class="text-center">45</td>
            </tr>
            <tr>
              <td>Maria Valdez</td>
              <td class="text-center">28</td>
              <td class="text-center">35</td>
              <td class="text-center">35</td>
              <td class="text-center">45</td>
              <td class="text-center">35</td>
              <td class="text-center">45</td>
            </tr>
          </tbody>
        </v-simple-table-->
        <v-data-table
          :headers="headers"
          :items="students"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption td-click"
          fixed-header
          :items-per-page="-1"
          height="550"
          hide-default-footer
        >
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  class="mr-1"
                  @click.stop="addGrade(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dense>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Agregar Calificacion</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editGrade(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dense>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar Calificacion</span>
            </v-tooltip>
            <!--v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									v-if="user.idRol == 1"
									icon
									:color="item.estado ? 'error' : 'success'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon v-if="user.idRol == 1" dense>{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
									<v-icon v-else dense>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span v-if="user.idRol == 1">{{item.estado ? 'Desactivar' : 'Activar'}}</span>
							<span v-else>Eliminar</span>
						</v-tooltip-->
          </template>
        </v-data-table>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogGrade" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formGrade">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Simulacro</p>
                    <v-select
                      v-if="editedIndex > 0"
                      v-model="formGrade.partial"
                      :items="partial"
                      item-text="name"
                      item-value="type"
                      label="Seleccione"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      @change="changePartial"
                      :rules="partialRules"
                    ></v-select>
                    <v-select
                      v-else
                      v-model="formGrade.partial"
                      :items="partial"
                      item-text="name"
                      item-value="type"
                      label="Seleccione"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="partialRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nota</p>
                    <v-text-field
                      v-model="formGrade.grade"
                      class="pt-0"
                      placeholder="Nota"
                      single-line
                      dense
                      outlined
                      :rules="gradeRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="loadingData"
              @click="closeGrade"
            >
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click="saveGrade"
            >
              <span class="text-capitalize">{{
                editedIndex > 0 ? "Guardar" : "Registrar"
              }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DialogGradesUpload
        :dialog="dialogUpload"
        :headers="headers"
        :grades="grades"
        :group="formGroup"
        :teacher="user"
        @closeDialogUpload="closeDialogUpload"
        @viewNotification="viewNotification"
      />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import * as XLSX from "xlsx";
import DialogGradesUpload from "@/components/teacher/dialog-grades-upload.vue";
export default {
  components: {
    DialogGradesUpload,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogUpload: false,
      headers: [],
      students: [],
      grades: [],
      semesters: [],
      groups: [],
      months: [],
      subjects: [],
      editedIndex: -1,
      formGroup: {
        idGroup: "",
        idSemester: "",
        idSubject: "",
        nameGroup: "",
        month: "",
        type: "",
      },
      semesterRules: [(value) => !!value || "Seleccione un semestre"],
      groupRules: [(value) => !!value || "Seleccione un grupo"],
      monthRules: [(value) => !!value || "Seleccione un mes"],
      typeRules: [
        (value) => value !== null || "Seleccione un tipo de evaluación",
      ],
      loading: false,
      loadingData: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      editedItem: {},
      items: [
        {
          text: "Calificaciones",
          disabled: false,
          href: "/grade",
        },
      ],
      turns: [],
      dialogGrade: false,
      formGrade: {
        idGroup: "",
        idStudent: "",
        idTeacher: "",
        idSubject: "",
        dateIngress: "",
        partial: "",
        grade: "",
      },
      gradeRules: [
        (value) => !!value || "Ingrese una nota",
        (value) =>
          /^\d{1,3}(\.\d{1,2})?$/.test(value) ||
          "La nota debe ser un número entre 0 y 100",
        (value) => {
          const number = parseFloat(value);
          return (
            (number >= 0 && number <= 100) || "La nota debe estar entre 0 y 100"
          );
        },
      ],
      partialRules: [(value) => !!value || "Seleccione un parcial"],
      partial: [],
      drills: 0,
      types: [],
    };
  },
  computed: {
    ...mapState(["user", "idDepartament"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar Calificacion"
        : "Editar Calificacion";
    },
  },
  watch: {
    dialogGrade(val) {
      val || this.closeGrade();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.selectSemesterTeacher();
  },
  methods: {
    viewNotification(data) {
      this.$refs.uploadInput.value = null;
      this.notification(data.type, data.text);
      if (data.students.length > 0) {
        this.students = data.students;
        this.dialogUpload = false;
      }
    },
    closeDialogUpload() {
      this.dialogUpload = false;
    },
    uploadFile(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];

          // Convierte la hoja de cálculo a JSON
          this.grades = XLSX.utils.sheet_to_json(worksheet);
          this.dialogUpload = true;
          this.$refs.uploadInput.value = null;
        };

        reader.readAsArrayBuffer(file);
      }
    },
    uploadXlsx() {
      this.$refs.uploadInput.click();
    },
    downloadXlsx() {
      let wb = XLSX.utils.book_new();
      if (this.students.length > 0) {
        const data = XLSX.utils.json_to_sheet(this.students);
        XLSX.utils.book_append_sheet(wb, data, "Calificaciones");
      }

      XLSX.writeFile(
        wb,
        `Calificaciones${this.formGroup.nameGroup}${
          this.formGroup.type === 0 ? "Simulacro" : "Examen"
        }${this.formGroup.month}.xlsx`
      );
    },
    changeMonth() {
      this.students = [];
      this.types.push(
        {
          value: 0,
          name: "Simulacro",
        },
        {
          value: 1,
          name: "Examen",
        }
      );
    },
    changeType() {},
    changePartial(item) {
      if (item == 1) {
        this.formGrade.grade = this.editedItem.s1;
      } else if (item == 2) {
        this.formGrade.grade = this.editedItem.s2;
      } else if (item == 3) {
        this.formGrade.grade = this.editedItem.s3;
      } else if (item == 4) {
        this.formGrade.grade = this.editedItem.s4;
      } else if (item == 5) {
        this.formGrade.grade = this.editedItem.s5;
      } else if (item == 6) {
        this.formGrade.grade = this.editedItem.s6;
      }
    },
    editGrade(item) {
      this.formGrade.idGroup = this.formGroup.idGroup;
      this.formGrade.idStudent = item.idUsuario;
      this.formGrade.idTeacher = this.user.idUsuario;
      this.formGrade.idSubject = this.formGroup.idSubject;
      this.editedItem = Object.assign({}, item);
      this.editedIndex = 1;
      this.partial = [];

      if (item.s1 != null) {
        this.partial.push({
          type: 1,
          name: "Simulacro 1",
        });
      }
      if (item.s2 != null) {
        this.partial.push({
          type: 2,
          name: "Simulacro 2",
        });
      }
      if (item.s3 != null) {
        this.partial.push({
          type: 3,
          name: "Simulacro 3",
        });
      }
      if (item.s4 != null) {
        this.partial.push({
          type: 4,
          name: "Simulacro 4",
        });
      }
      if (item.s5 != null) {
        this.partial.push({
          type: 5,
          name: "Simulacro 5",
        });
      }
      if (item.s6 != null) {
        this.partial.push({
          type: 6,
          name: "Simulacro 6",
        });
      }

      this.dialogGrade = true;
    },
    async updateGrade(data) {
      try {
        let response = await axios.put("exams/updateExam", data);
        if (response.status == 200) {
          this.closeGrade();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectStudentsByIdGroup(this.formGroup.idGroup);
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertGrade(data) {
      try {
        let response = await axios.post("exams/insertExam", data);
        if (response.status == 200) {
          if (response.data.type == 0) {
            this.loadingData = false;
            this.notification("warning", response.data["msg"]);
          } else {
            this.closeGrade();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.selectStudentsByIdGroup(this.formGroup.idGroup);
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveGrade() {
      if (this.$refs.formGrade.validate()) {
        if (this.editedIndex > 0) {
          let data = {
            idGroup: this.formGrade.idGroup,
            idStudent: this.formGrade.idStudent,
            idTeacher: this.formGrade.idTeacher,
            idSubject: this.formGrade.idSubject,
            partial: this.formGrade.partial,
            grade: this.formGrade.grade,
          };
          this.loadingData = true;

          this.updateGrade(data);
        } else {
          let data = {
            idGroup: this.formGrade.idGroup,
            idStudent: this.formGrade.idStudent,
            idTeacher: this.formGrade.idTeacher,
            idSubject: this.formGrade.idSubject,
            partial: this.formGrade.partial,
            grade: this.formGrade.grade,
          };
          this.loadingData = true;

          this.insertGrade(data);
        }
      }
    },
    addGrade(item) {
      this.formGrade.idGroup = this.formGroup.idGroup;
      this.formGrade.idStudent = item.idUsuario;
      this.formGrade.idTeacher = this.user.idUsuario;
      this.formGrade.idSubject = this.formGroup.idSubject;
      this.dialogGrade = true;
    },
    /*TODO: Listar estudiantes por grupo, mes y simulacro*/
    async selectStudentsGradeByIdGroup(params) {
      this.students = [];
      try {
        let response = await axios.get(`courses/selectStudentsGradeByIdGroup`, {
          params: params,
        });
        if (response.status == 200) {
          this.students = response.data.students;
          this.students.map((value, index) => {
            value.nro = index + 1;
          });
          /*response.data.map((value, index) => {
            this.students.push({
              nro: index + 1,
              idUsuario: value.idUsuario,
              estudiante: value.estudiante,
              ci: value.ci,
              telefono: value.ci,
              email: value.email,
              s1: value.s1,
              s2: value.s2,
              s3: value.s3,
              s4: value.s4,
              s5: value.s5,
              s6: value.s6,
              nfin: (
                (Number(value.s1) +
                  Number(value.s2) +
                  Number(value.s3) +
                  Number(value.s4) +
                  Number(value.s5) +
                  Number(value.s6)) /
                this.drills
              ).toFixed(1),
            });
          });*/
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    findStudents() {
      if (this.$refs.formGradeSearch.validate()) {
        this.loading = true;
        this.students = [];
        const params = {
          idGroup: this.formGroup.idGroup,
          month: this.formGroup.month,
          type: this.formGroup.type,
        };
        this.selectStudentsGradeByIdGroup(params);
      }
    },
    defineHeaders() {
      this.headers.push(
        {
          text: "Nro",
          value: "nro",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Estudiante",
          value: "nombreCompleto",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Ci",
          value: "ci",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        }
      );

      this.subjects.forEach((element) => {
        this.headers.push({
          text: element.codigoMateria,
          value: element.codigoMateria,
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        });
      });
      /*for (let i = 0; i < this.drills; i++) {
        this.headers.push({
          text: `S${i + 1}`,
          value: `s${i + 1}`,
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        });
      }
      this.headers.push(
        {
          text: "NFin",
          value: "nfin",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        }
      );*/
    },
    definePartial() {
      for (let i = 0; i < this.drills; i++) {
        this.months.push({
          value: i + 1,
          name: `Mes ${i + 1}`,
        });
        this.partial.push({
          type: i + 1,
          name: `Simulacro ${i + 1}`,
        });
      }
    },
    findSubject(item) {
      this.subjects = [];
      this.formGroup.idSubject = "";
      this.formGroup.nameGroup = "";
      this.students = [];

      const obj = this.groups.find((group) => group.idGrupo === item);
      this.subjects = obj.subjects;
      this.drills = obj.cuotas;
      this.formGroup.nameGroup = obj.nombreGrupo;

      this.headers = [];

      this.defineHeaders();
      this.definePartial();
    },
    groupFilter(item, queryText) {
      const textOne = item.nombreGrupo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async selectGroupBySemester(idSemester) {
      try {
        let response = await axios.get(
          `groupTeachers/selectGroupBySemester/${this.idDepartament}/${this.user.idUsuario}/${idSemester}`
        );
        if (response.status == 200) {
          this.groups = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    findGroup(item) {
      this.groups = [];
      this.subjects = [];
      this.formGroup.idGroup = "";
      this.formGroup.idSubject = "";
      this.students = [];
      this.drills = 0;
      this.partial = [];

      this.selectGroupBySemester(item);
    },
    semesterFilter(item, queryText) {
      const textOne = item.nombreSemestre.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async deleteGroup(data) {
      try {
        let response = await axios.put("groups/deleteGroup", data);
        if (response.status == 200) {
          this.closeDelete();
          this.notification(
            "warning",
            this.user.idRol == 1 ? response.data["msg"] : "Se elimino el grupo"
          );
          this.selectGroups();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async selectSemesterTeacher() {
      try {
        let response = await axios.get(
          `semesters/selectSemesterTeacher/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.semesters = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let data = {
        idGroup: this.editedItem.idGrupo,
        state: this.editedItem.estado ? 0 : 1,
      };
      this.deleteGroup(data);
    },

    closeGrade() {
      this.dialogGrade = false;
      this.formGrade.dateIngress = "";
      this.formGrade.grade = "";
      this.formGrade.idGroup = "";
      this.formGrade.idStudent = "";
      this.formGrade.idSubject = "";
      this.formGrade.idTeacher = "";
      this.formGrade.partial = "";
      this.definePartial();
      this.editedIndex = -1;
      this.$refs.formGrade.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editItem = "";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>