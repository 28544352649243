import { render, staticRenderFns } from "./payment-category-view.vue?vue&type=template&id=6d0cece9&"
import script from "./payment-category-view.vue?vue&type=script&lang=js&"
export * from "./payment-category-view.vue?vue&type=script&lang=js&"
import style0 from "./payment-category-view.vue?vue&type=style&index=0&id=6d0cece9&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports