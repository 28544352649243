import {
	getModules,
	getPermissionUser,
	setPermissionUser,
	updatePermission,
} from "@/api";
import { usePermissionStore } from "@/store/permission";
import { storeToRefs } from "pinia";

export const usePermissions = () => {
	const permissionsStore = usePermissionStore();
	const {
		loading,
		modules,
		store,
		dialogPermissionUser,
		permissionsUser,
		formPermission,
		moduleRules,
		headersPermissions,
		permissionForm,
		loadingPermission,
		checkPermission,
	} = storeToRefs(permissionsStore);

	const selectModules = async (params) => {
		try {
			const response = await getModules(params);
			if (response.status == 200) {
				permissionsStore.setModules(response.data.modules);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const selectPermissionsUser = async (params) => {
		try {
			const response = await getPermissionUser(params);
			if (response.status == 200) {
				permissionsStore.setPermissionUser(response.data.permissions);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const insertPermissionUser = async (data) => {
		try {
			const response = await setPermissionUser(data);
			if (response.status == 200) {
				permissionsStore.changeLoading(false);
				selectPermissionsUser({ idUser: data.idUser });
				selectModules({ idUser: data.idUser });
			}
		} catch (error) {
			console.log(error);
			permissionsStore.changeLoading(false);
		}
	};

	const updatePermissionUser = async (data) => {
		try {
			const response = await updatePermission(data);
			if (response.status == 200) {
				permissionsStore.changeLoadingPermission(false);
			}
		} catch (error) {
			console.log(error);
			permissionsStore.changeLoadingPermission(false);
		}
	};

	const saveAssignPermission = async () => {
		const valid = await permissionForm.value.validate();
		if (valid) {
			permissionsStore.changeLoading(true);
			const data = {
				idUser: formPermission.value.idUser,
				idModule: formPermission.value.idModule,
			};
			insertPermissionUser(data);
		}
	};

	const customFilter = (item, queryText) => {
		const textOne = item.nombreModulo.toLowerCase();
		const searchText = queryText.toLowerCase();

		return textOne.indexOf(searchText) > -1;
	};

	const closePermissionUser = () => {
		permissionsStore.changeDialogPermissionUser(false);
		permissionsStore.clearFormPermission();
		permissionForm.value.resetValidation();
		permissionsStore.clearModules();
		permissionsStore.clearPermissionUser();
	};

	const editPermissions = (item) => {
		selectPermissionsUser({ idUser: item.idUsuario });
		selectModules({ idUser: item.idUsuario });
		formPermission.value.idUser = item.idUsuario;
		permissionsStore.changeDialogPermissionUser(true);
	};

	const changePermissionUser = (item) => {
		permissionsStore.changeLoadingPermission(true);
		const data = {
			idUser: formPermission.value.idUser,
			idModule: item.idModulo,
			asign: item.asignado,
		};
		updatePermissionUser(data);
	};

	return {
		//props
		loading,
		modules,
		store,
		dialogPermissionUser,
		permissionsUser,
		formPermission,
		moduleRules,
		headersPermissions,
		permissionForm,
		loadingPermission,
		checkPermission,
		//methods
		saveAssignPermission,
		customFilter,
		closePermissionUser,
		editPermissions,
		selectModules,
		changePermissionUser,
	};
};
