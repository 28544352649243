import { defineStore } from "pinia";
import { useStore } from ".";

export const usePermissionStore = defineStore("usePermissionStore", {
	state: () => ({
		store: useStore(),
		dialogPermissionUser: false,
		permissionsUser: [],
		modules: [],
		loading: false,
		formPermission: {
			idModule: "",
			idUser: "",
		},
		permissionForm: null,
		moduleRules: [(value) => !!value || "Seleccione un módulo"],
		headersPermissions: [
			{
				text: "Módulo",
				value: "nombreModulo",
				align: "left",
				sortable: false,
				class: "font-weight-bold blue-grey lighten-5",
			},
			{
				text: "Asignado",
				value: "asignado",
				align: "center",
				sortable: false,
				class: "font-weight-bold blue-grey lighten-5",
			},
		],
		loadingPermission: false,
	}),
	actions: {
		changeLoadingPermission(value) {
			this.loadingPermission = value;
		},
		clearFormPermission() {
			this.formPermission = {
				idModule: "",
				idUser: "",
			};
		},
		changeDialogPermissionUser(value) {
			this.dialogPermissionUser = value;
		},
		setPermissionUser(value) {
			this.permissionsUser = [...value];
		},
		setModules(value) {
			this.modules = [...value];
		},
		changeLoading(value) {
			this.loading = value;
		},
		clearPermissionUser() {
			this.permissionsUser = [];
		},
		clearModules() {
			this.modules = [];
		},
	},
});
